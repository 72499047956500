import "@/src/styles/global.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SnackbarKey, SnackbarProvider } from "notistack";
import { createTheme, ThemeProvider } from "@mui/material";
import NotFound from "@/src/components/common/NotFound";
import AuthMiddleware from "@/src/middleware/AuthMiddleware";
import LoginPage from "@/src/pages/login/LoginPage";
import Home from "@/src/pages/Home";
import OAuth2Redirect from "@/src/pages/oauth2/redirect";
import NotistackCloseButton from "@/src/components/common/NotistackCloseButton";
import Layout from "@/src/components/common/Layout";
import AllRoles from "./pages/all-roles/AllRoles";

const App = () => {
  const handleCloseSnackbar: React.FC<SnackbarKey> = (snackbarKey) => {
    return <NotistackCloseButton snackbarKey={snackbarKey} />;
  };

  const theme = createTheme({
    palette: {
      mode: "light",
      background: {
        paper: "#fff",
      },
      text: {
        primary: "#173A5E",
        secondary: "#46505A",
      },
      action: {
        active: "#001E3C",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        preventDuplicate={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        action={handleCloseSnackbar}
      >
        <Router>
          <Routes>
            <Route element={<AuthMiddleware />}>
              <Route element={<Layout />}>
                <Route path="/" element={<Home />} />
                <Route path="/all-roles" element={<AllRoles />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Route>
            <Route path="login" element={<LoginPage />} />
            <Route path="/oauth2/redirect" element={<OAuth2Redirect />} />
          </Routes>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
