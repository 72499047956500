import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box, Button, Typography } from '@mui/material';
import AddCardIcon from '@mui/icons-material/AddCard';

import { RootState } from '@/src/datastore/store';
import UseNavigation from '@/src/hooks/useNavigation';
import AllRolesService from '@/src/services/all-roles/allRolesService';
import CustomTablePaginationActions from '@/src/components/common/CustomTablePaginationActions';
import NoAuthorization from '@/src/components/common/NoAuthorization';
import AppConfig from '@/src/config/AppConfig';
import AppMessages from '@/src/config/message_config/AppMessages';
import lightTheme from '@/src/styles/themes/lightTheme';
import { ILooseObject, IPagination } from '@/src/types/common.interface';

const Roles = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableDetails, setTableDetails] = useState<ILooseObject>({
    columns: [],
    rows: []
  });
  const [paginationData] = useState<IPagination>({
    page: 0,
    size: -1
  });

  const userData = useSelector((state: RootState) => state?.userDetails);
  const navigate = UseNavigation();

  const uniqueApplicationKeys: string[] = Array.from(
    userData?.organizations?.reduce((acc: Set<string>, org: ILooseObject) => {
      org?.applications?.forEach((app: ILooseObject) =>
        app?.application_key ? acc.add(app?.application_key) : ''
      );
      return acc;
    }, new Set())
  );

  const userAppPermissions: ILooseObject = useSelector(
    (state: RootState) => state?.userDetails?.OptionsAndPermissions?.appPermissions
  );

  const getAppPermissions = () => {
    //filtering permissions who have users applications
    const applicationKeys = uniqueApplicationKeys;
    return Object.entries(userAppPermissions as Record<string, string[]>)?.reduce(
      (acc: string[], [key, permissions]: [string, string[]]) => {
        if (applicationKeys?.includes(key)) {
          return Array.from(new Set([...acc, ...permissions]));
        }
        return acc;
      },
      []
    );
  };

  const getAllRoles = async (paginationData: IPagination, applicationKeys: string[]) => {
    try {
      setIsLoading(true);
      const results = await AllRolesService.getAllRolesList({
        pagination: paginationData,
        filters: {
          application_keys: applicationKeys
        }
      });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } else if (!results.error.code) {
        setTableDetails({
          columns: results.columns,
          rows: results.rows
        });
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (uniqueApplicationKeys?.length > 0) {
      if (getAppPermissions()?.includes('all_role_view')) {
        getAllRoles(paginationData, uniqueApplicationKeys);
      }
    } else {
      enqueueSnackbar(AppMessages.Error.empty_organization_keys, {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!getAppPermissions()?.includes('all_role_view')) {
    return <NoAuthorization />;
  }

  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" color="primary.dark">
          All Roles
        </Typography>
        <Box>
          <Button
            variant="outlined"
            size="small"
            startIcon={<AddCardIcon />}
            disabled={!getAppPermissions()?.includes('api_permission_add')} //TODO: When enable organization level add role then add the permission of oganization level also
            onClick={() => navigate.navigateTo('/roles/add-role')}
            sx={{ textTransform: 'none' }}>
            Add role
          </Button>
        </Box>
      </Box>
      <DataGrid
        getRowId={(row) => row?.role_key}
        rows={tableDetails.rows}
        columns={tableDetails.columns}
        disableRowSelectionOnClick
        disableColumnSelector
        disableColumnMenu
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        getRowHeight={() => 'auto'}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            textOverflow: 'clip',
            whiteSpace: 'break-spaces',
            lineHeight: 1.15,
            fontWeight: 600
          },
          '& .MuiDataGrid-row': {
            minHeight: '52px !important'
          },
          '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
          },
          '& .MuiDataGrid-main': {
            overflow: 'unset'
          },
          '& .MuiDataGrid-columnHeaders': {
            position: 'sticky',
            top: 63,
            zIndex: 1
          },
          '& .MuiDataGrid-container--top': {
            zIndex: 1
          },
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: lightTheme.palette.grey[100]
          }
        }}
        slotProps={{
          pagination: {
            ActionsComponent: CustomTablePaginationActions
          },
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 }
          }
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 100, page: 0 }
          }
        }}
        paginationMode="client"
        loading={isLoading}
        pageSizeOptions={[20, 50, 100]}
      />
    </Box>
  );
};

export default Roles;
