import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar } from 'notistack';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid2,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  IAttributeOptions,
  ILooseObject,
  INPUT_FIELD_TYPE_MAP,
  INPUT_TYPES_ENUM,
  UI_TYPE_INPUT_TYPE_MAP
} from '@/src/types/common.interface';
import AttributesConfig, { IAttributeDef } from '@/src/config/AttributesConfig';
import AppMessages from '@/src/config/message_config/AppMessages';
import AppConfig from '@/src/config/AppConfig';
import UseNavigation from '@/src/hooks/useNavigation';
import { RootState } from '@/src/datastore/store';
import SchemaValidations from '@/src/utils/SchemaValidations';
import AllRolesService from '@/src/services/all-roles/allRolesService';
import AllUsersService from '@/src/services/all-users/allUsersService';
import DynamicBreadCrumbs from '@/src/components/common/DynamicBreadCrumbs';
import DynamicInput from '@/src/components/common/DynamicInput';
import NoAuthorization from '@/src/components/common/NoAuthorization';
import lightTheme from '@/src/styles/themes/lightTheme';

const AddRole = () => {
  const [openAccordion, setOpenAccordion] = useState<string | boolean>('application-level');
  const [inputOptions, setInputOptions] = useState<ILooseObject>({});
  const [isOptionsLoading, setIsOptionsLoading] = useState<ILooseObject>({});
  const disableOrganizationLevel: boolean = true; //TODO: Remove this constant when implement flow of orgLevel

  const navigation = UseNavigation();

  const applicationSchema = SchemaValidations.AddRoles(
    AttributesConfig.ADD_ROLE_INPUT?.filter(
      (attribute: IAttributeDef) => attribute?.name !== 'organization'
    )
  );

  const organizationSchema = SchemaValidations.AddRoles(
    AttributesConfig.ADD_ROLE_INPUT?.filter(
      (attribute: IAttributeDef) => attribute?.name !== 'application'
    )
  );

  const { applicationsOptions, organizationsOptions, appPermissions, orgPermissions } = useSelector(
    (state: RootState) => state?.userDetails?.OptionsAndPermissions
  );

  const {
    control: applicationControl,
    handleSubmit: applicationHandleSubmit,
    formState: {
      errors: applicationErrors,
      isSubmitting: applicationIsSubmitting,
      isDirty: applicationIsDirty
    },
    clearErrors: applicationClearErrors,
    reset: applicationReset,
    watch: applicationWatch
  } = useForm({
    defaultValues: AttributesConfig.ADD_ROLE_INPUT?.filter(
      (attribute: IAttributeDef) => attribute?.name !== 'organization'
    )?.reduce((acc: ILooseObject, attribute: IAttributeDef) => {
      acc[attribute?.name] = '';
      return acc;
    }, {}),
    resolver: yupResolver(applicationSchema, { abortEarly: false }),
    mode: 'onBlur'
  });

  const {
    control: organizationControl,
    handleSubmit: organizationHandleSubmit,
    formState: {
      errors: organizationErrors,
      isSubmitting: organizationIsSubmitting,
      isDirty: organizationIsDirty
    },
    clearErrors: organizationClearErrors,
    reset: organizationReset,
    watch: organizationWatch
  } = useForm({
    defaultValues: AttributesConfig.ADD_ROLE_INPUT?.filter(
      (attribute: IAttributeDef) => attribute?.name !== 'application'
    )?.reduce((acc: ILooseObject, attribute: IAttributeDef) => {
      acc[attribute?.name] = '';
      return acc;
    }, {}),
    resolver: yupResolver(organizationSchema, { abortEarly: false }),
    mode: 'onBlur'
  });

  const getAppAllPermissions = () => {
    //filtering permissions who have users applications
    return Object.entries(appPermissions as Record<string, string[]>)?.reduce(
      (acc: string[], [key, permissions]: [string, string[]]) => {
        return Array.from(new Set([...acc, ...permissions]));
      },
      []
    );
  };

  const getAllUserTypeList = async (): Promise<IAttributeOptions[]> => {
    try {
      setIsOptionsLoading((pre) => ({ ...pre, role_type: true }));
      const results = await AllUsersService.getAllUserTypeList();

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        return [];
      } else if (!results.error.code) {
        const convertedUserTypeOption = results?.data?.map((user: ILooseObject) => ({
          label: user?.ui_label ?? '-',
          value: user?.name ?? '-'
        }));
        return convertedUserTypeOption;
      }
      return [];
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
      return [];
    } finally {
      setIsOptionsLoading((pre) => ({ ...pre, role_type: false }));
    }
  };

  const getAllOrganizationList = async () => {
    try {
      setIsOptionsLoading((pre) => ({ ...pre, organization: true }));
      const collectionAuthorizedOrg = Object.entries(
        orgPermissions as Record<string, string[]>
      )?.reduce((acc: string[], [key, value]: [string, string[]]) => {
        if (value?.includes('organization_role_add_permission')) {
          acc = [...acc, key];
        }
        return acc;
      }, []);
      const collectedOptions = Object.entries(
        organizationsOptions as Record<string, IAttributeOptions>
      )?.reduce((acc: IAttributeOptions[], [key, value]: [string, IAttributeOptions]) => {
        if (collectionAuthorizedOrg?.includes(key)) {
          acc.push(value);
        }
        return acc;
      }, []);

      setInputOptions((pre) => ({ ...pre, organization: collectedOptions }));
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsOptionsLoading((pre) => ({ ...pre, organization: false }));
    }
  };

  const getAllApplicationsList = async () => {
    try {
      setIsOptionsLoading((pre) => ({ ...pre, application: true }));
      const collectionAuthorizedApp = Object.entries(
        appPermissions as Record<string, string[]>
      )?.reduce((acc: string[], [key, value]: [string, string[]]) => {
        if (value?.includes('api_permission_add')) {
          acc = [...acc, key];
        }
        return acc;
      }, []);
      const collectedOptions = Object.entries(
        applicationsOptions as Record<string, IAttributeOptions>
      )?.reduce((acc: IAttributeOptions[], [key, value]: [string, IAttributeOptions]) => {
        if (collectionAuthorizedApp?.includes(key)) {
          acc.push(value);
        }
        return acc;
      }, []);

      setInputOptions((pre) => ({ ...pre, application: collectedOptions }));
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsOptionsLoading((pre) => ({ ...pre, application: false }));
    }
  };

  const getAllPermissions = async (applicationKey: string) => {
    try {
      setIsOptionsLoading((prev: ILooseObject) => ({ ...prev, permission_key: true }));
      const results = await AllRolesService.getAllPermissions({ applicationKey });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        setInputOptions((prev) => ({ ...prev, permission_key: [] }));
      } else if (!results.error.code) {
        const permissionKeys =
          results?.data?.map((app: ILooseObject) => ({
            label: app?.ui_label ?? '-',
            value: app?.permission_key ?? '-'
          })) ?? [];
        setInputOptions((prev) => ({ ...prev, permission_key: permissionKeys }));
      }
      return [];
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
      setInputOptions((prev) => ({ ...prev, permission_key: [] }));
    } finally {
      setIsOptionsLoading((prev: ILooseObject) => ({ ...prev, permission_key: false }));
    }
  };

  const getAllRoles = async (applicationKeys: string) => {
    try {
      setIsOptionsLoading((prev: ILooseObject) => ({ ...prev, parent_role_key: true }));
      const results = await AllRolesService.getAllRolesByApplication({
        filters: {
          application_keys: applicationKeys
        }
      });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } else if (!results.error.code) {
        const roles =
          results?.data?.roles?.map((role: ILooseObject) => ({
            label: role?.ui_label ?? '-',
            value: role?.role_key ?? '-'
          })) ?? [];
        setInputOptions((prev) => ({ ...prev, parent_role_key: roles }));
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsOptionsLoading((prev: ILooseObject) => ({ ...prev, parent_role_key: false }));
    }
  };

  const AddRolesToApplication = async (data: ILooseObject) => {
    const { application: applicationKey, ...others } = data ?? {};

    try {
      const results = await AllRolesService.AddRolesToApplication({
        applicationKey,
        payload: others
      });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } else if (!results.error.code) {
        enqueueSnackbar(AppMessages.Success.role_added, {
          variant: 'success',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.success
        });
        navigation.navigateTo('/roles');
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
      return [];
    }
  };

  const AddRolesToOrganization = async (data: ILooseObject) => {
    const { organization: organizationKey, ...others } = data ?? {};

    try {
      const results = await AllRolesService.AddRolesToOrganization({
        organizationKey,
        payload: others
      });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } else if (!results.error.code) {
        enqueueSnackbar(AppMessages.Success.role_added, {
          variant: 'success',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.success
        });
        navigation.navigateTo('/roles');
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
      return [];
    }
  };

  const handleOpenAccordion =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setOpenAccordion(newExpanded ? panel : false);
    };

  const handleApplicationRoleSubmit = (data: ILooseObject) => {
    const updatedData = { ...data, reference_id: '1' };
    const filteredData = Object.entries(updatedData).reduce((acc: ILooseObject, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {});
    AddRolesToApplication(filteredData);
  };

  const handleResetApplication = () => {
    applicationClearErrors();
    applicationReset();
  };

  const handleResetOrganization = () => {
    organizationClearErrors();
    organizationReset();
  };

  const handleOrganizationRoleSubmit = (data: ILooseObject) => {
    const updatedData = { ...data, Reference_id: '1' };
    AddRolesToOrganization(updatedData);
  };

  useEffect(() => {
    async function fetchData() {
      getAllApplicationsList();
      getAllOrganizationList();
      const userType = await getAllUserTypeList();
      setInputOptions((pre) => ({
        ...pre,
        role_type: userType
      }));
    }
    //If user have no permission then no process
    if (getAppAllPermissions()?.includes('api_permission_add')) {
      //TODO: When enable organization level then add the permission of organizations
      // which will be collected from orgPermission
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { application: applicationKey } = applicationWatch();

    if (applicationKey) {
      getAllPermissions(applicationKey);
      getAllRoles(applicationKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(applicationWatch('application'))]);

  if (!getAppAllPermissions()?.includes('api_permission_add')) {
    return <NoAuthorization />;
  }

  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      <DynamicBreadCrumbs
        breadCrumbsDetails={[
          { key: 1, name: 'All roles', redirectPath: '/roles' },
          { key: 2, name: 'Add role' }
        ]}
      />
      <Box display="flex" flexDirection="column" rowGap={2}>
        <Accordion
          expanded={openAccordion === 'application-level'}
          onChange={handleOpenAccordion('application-level')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="application-level"
            id="application-level-header">
            <Typography component="span" fontWeight="bold">
              Application level
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {!getAppAllPermissions()?.includes('api_permission_add') ? (
              <NoAuthorization />
            ) : (
              <Box
                component="form"
                border={1}
                borderColor={lightTheme.palette.grey[300]}
                borderRadius={3}
                onSubmit={applicationHandleSubmit(handleApplicationRoleSubmit)}
                sx={{ p: 2, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
                <Grid2 container columnSpacing={10}>
                  {AttributesConfig.ADD_ROLE_INPUT?.filter(
                    (attribute: IAttributeDef) => attribute?.name !== 'organization'
                  )?.map((attribute: IAttributeDef) => {
                    const inputType = UI_TYPE_INPUT_TYPE_MAP[attribute?.ui_type?.toUpperCase()];
                    const options = attribute?.options ?? inputOptions?.[attribute?.name] ?? [];

                    const inputFieldType: INPUT_TYPES_ENUM =
                      INPUT_FIELD_TYPE_MAP[attribute?.value_type?.toUpperCase()];

                    const isVisible: boolean = !!attribute?.is_visible;
                    if (!isVisible) {
                      return null;
                    }

                    return (
                      <Grid2
                        container
                        size={{ xs: 6 }}
                        key={attribute?.attribute_id}
                        alignItems="center">
                        {/* UI label */}
                        <Grid2 size={{ xs: 4 }} display="flex" alignItems="center">
                          <Box sx={{ textAlign: 'left', pb: 4 }}>
                            <Typography>{`${attribute?.ui_label} ${attribute?.is_mandatory ? '*' : ''}`}</Typography>
                          </Box>
                        </Grid2>
                        {/* DynamicInput */}
                        <Grid2 size={{ xs: 8 }}>
                          <Controller
                            name={attribute.name as string}
                            control={applicationControl}
                            render={({ field: { onChange, onBlur, value, name } }) => (
                              <DynamicInput
                                type={inputType}
                                id={attribute?.attribute_id}
                                label={attribute?.ui_label}
                                value={value}
                                hasError={!!(applicationErrors?.[name]?.message as string)}
                                helperText={(applicationErrors?.[name]?.message as string) ?? ' '}
                                options={options}
                                loading={isOptionsLoading?.[attribute?.name]}
                                maxLength={attribute?.max_length}
                                placeholder={attribute?.placeholder}
                                disableClearable={attribute?.disable_clearable}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                  onChange(e);
                                  applicationClearErrors(name);
                                }}
                                onBlur={onBlur}
                                inputType={inputFieldType}
                              />
                            )}
                          />
                        </Grid2>
                      </Grid2>
                    );
                  })}
                </Grid2>
                <Box display="flex" flexDirection="row" alignSelf="flex-end" columnGap={2}>
                  <Button variant="outlined" size="small" onClick={handleResetApplication}>
                    Reset
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="small"
                    disabled={Object.keys(applicationErrors)?.length > 0 || !applicationIsDirty}
                    loading={applicationIsSubmitting}>
                    Submit
                  </LoadingButton>
                </Box>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
        {disableOrganizationLevel ? (
          ''
        ) : (
          <Accordion
            expanded={openAccordion === 'organization-level'}
            onChange={handleOpenAccordion('organization-level')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="organization-level"
              id="organization-level-header">
              <Typography component="span" fontWeight="bold">
                Organization level
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                component="form"
                border={1}
                borderColor={lightTheme.palette.grey[300]}
                borderRadius={3}
                onSubmit={organizationHandleSubmit(handleOrganizationRoleSubmit)}
                sx={{ p: 2, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
                <Grid2 container columnSpacing={10}>
                  {AttributesConfig.ADD_ROLE_INPUT?.filter(
                    (attribute: IAttributeDef) => attribute?.name !== 'application'
                  )?.map((attribute: IAttributeDef) => {
                    const inputType = UI_TYPE_INPUT_TYPE_MAP[attribute?.ui_type?.toUpperCase()];

                    const options = attribute?.options ?? inputOptions?.[attribute?.name] ?? [];

                    const inputFieldType: INPUT_TYPES_ENUM =
                      INPUT_FIELD_TYPE_MAP[attribute?.value_type?.toUpperCase()];

                    const isVisible: boolean = !!attribute?.is_visible;
                    if (!isVisible) {
                      return null;
                    }

                    return (
                      <Grid2
                        container
                        size={{ xs: 6 }}
                        key={attribute?.attribute_id}
                        alignItems="center">
                        {/* UI label */}
                        <Grid2 size={{ xs: 4 }} display="flex" alignItems="center">
                          <Box sx={{ textAlign: 'left', pb: 4 }}>
                            <Typography>{`${attribute?.ui_label} ${attribute?.is_mandatory ? '*' : ''}`}</Typography>
                          </Box>
                        </Grid2>
                        {/* DynamicInput */}
                        <Grid2 size={{ xs: 8 }}>
                          <Controller
                            name={attribute.name as string}
                            control={organizationControl}
                            render={({ field: { onChange, onBlur, value, name } }) => (
                              <DynamicInput
                                type={inputType}
                                id={attribute?.attribute_id}
                                label={attribute?.ui_label}
                                value={value}
                                hasError={!!(organizationErrors?.[name]?.message as string)}
                                helperText={(organizationErrors?.[name]?.message as string) ?? ' '}
                                options={options}
                                loading={isOptionsLoading?.[attribute?.name]}
                                maxLength={attribute?.max_length}
                                placeholder={attribute?.placeholder}
                                disableClearable={attribute?.disable_clearable}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                  onChange(e);
                                  organizationClearErrors(name);
                                }}
                                onBlur={onBlur}
                                inputType={inputFieldType}
                              />
                            )}
                          />
                        </Grid2>
                      </Grid2>
                    );
                  })}
                </Grid2>
                <Box display="flex" flexDirection="row" alignSelf="flex-end" columnGap={2}>
                  <Button variant="outlined" size="small" onClick={handleResetOrganization}>
                    Reset
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="small"
                    disabled={Object.keys(organizationErrors)?.length > 0 || !organizationIsDirty}
                    loading={organizationIsSubmitting}>
                    Submit
                  </LoadingButton>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </Box>
  );
};

export default AddRole;
