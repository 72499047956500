import { ReactNode, useEffect, useState } from "react";
import { useLocation, Outlet, useNavigate } from "react-router";
import { UsersThree } from "@phosphor-icons/react";
import packageInfo from "@/src/../package.json";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import LogoutIcon from "@mui/icons-material/Logout";
import "@/src/styles/layout.scss";
import activeRoles from "@/public/icons/active-roles.svg";
import inActiveRoles from "@/public/icons/inactive-roles.svg";
import jswOneLogo from "@/public/icons/Logo.svg";
import AppConfig, { INavigationMenu } from "@/src/config/AppConfig";
import LocalStorage from "@/src/utils/LocalStorage";

const Layout = () => {
  const [userName, setUserName] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();

  const userData = LocalStorage.getItem("userData");

  const handleLogoutUser = () => {
    LocalStorage.clearItems();
    navigate("/login");
  };

  const getMenuIcon = (
    menuProperties: INavigationMenu,
    isSelected: boolean
  ) => {
    let iconName = menuProperties?.icon;
    if (!menuProperties?.icon) {
      iconName = isSelected
        ? menuProperties?.ActiveIcon
        : menuProperties?.InActiveIcon;
    }

    switch (iconName) {
      case "users":
        return (
          <Tooltip title={menuProperties?.label}>
            <UsersThree
              weight={isSelected ? "fill" : "thin"}
              className={isSelected ? "activeMenuIcon" : "inActiveMenuIcon"}
            />
          </Tooltip>
        );
      case "activeRoles":
        return (
          <Tooltip title={menuProperties?.label}>
            <img
              src={activeRoles}
              alt={menuProperties?.label}
              className="activeMenuIcon"
            />
          </Tooltip>
        );
      case "inActiveRoles":
        return (
          <Tooltip title={menuProperties?.label}>
            <img
              src={inActiveRoles}
              alt={menuProperties?.label}
              className="inActiveMenuIcon"
            />
          </Tooltip>
        );

      default:
        break;
    }
  };

  const getMenuItem = (menuItem: INavigationMenu): ReactNode => {
    const isSelected: boolean = menuItem.applicableRoutes.includes(
      location.pathname
    );
    return (
      <Box
        key={menuItem.id}
        onClick={() => navigate(menuItem.route)}
        sx={{ cursor: "pointer" }}
      >
        {getMenuIcon(menuItem, isSelected)}
      </Box>
    );
  };

  useEffect(() => {
    if (userData?.name) {
      setUserName(userData?.name);
    }
  }, userData?.name);

  return (
    <Box position="relative">
      <Box className="layoutMenuBar">
        <Box
          display="flex"
          flexDirection="row"
          columnGap={2}
          alignItems="center"
        >
          <img
            src={jswOneLogo}
            height={36}
            alt="jsw one logo"
            style={{ paddingBottom: 8 }}
          />
          <Typography variant="body2" fontWeight={600} fontSize={24}>
            {AppConfig.APP_DISPLAY_NAME}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" columnGap={4}>
          <Typography color="text.secondary">
            Welcome, <b>{userName}</b>
          </Typography>
          <IconButton
            aria-label="logout"
            size="small"
            onClick={handleLogoutUser}
          >
            <LogoutIcon color="error" />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box className="layoutMenuSideBar">
          {AppConfig.NAVIGATION_MENUS?.map((menu: INavigationMenu) => {
            return <Box key={menu?.id}>{getMenuItem(menu)}</Box>;
          })}
          <Box textAlign="center" mb={2} mt="auto">
            <Typography variant="subtitle2">v{packageInfo?.version}</Typography>
            {process.env.REACT_APP_APP_ENV !== "PROD" && (
              <Chip
                label={process.env.REACT_APP_APP_ENV}
                color="warning"
                size="small"
              />
            )}
          </Box>
        </Box>
        <Box className="layoutOutlet">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
