import { IAttributeOptions, ILooseObject } from '@/src/types/common.interface';

export interface IAttributeDef {
  attribute_id: number;
  name: string;
  ui_label: string;
  ui_type: 'string' | 'select' | 'integer' | 'multi_select_chips' | 'boolean' | 'multi_select';
  value_type: 'text' | 'number' | 'boolean' | 'array';
  ui_rank: number;
  status: string;
  class: string;
  is_visible: boolean;
  is_mandatory: boolean;
  is_disabled: boolean;
  is_editable: boolean;
  is_searchable: boolean;
  select_source_type: string | null;
  default_value: string | null;
  override_config?: ILooseObject | null;
  options?: IAttributeOptions[] | null;
  placeholder?: string;
  max_length?: number;
  disable_clearable?: boolean;
}

export interface IAttributesConfig {
  ALL_USERS_FILTERS_INPUT: IAttributeDef[];
  ADD_USER_INPUT: IAttributeDef[];
  USER_DETAILS: IAttributeDef[];
  ROLE_DETAILS: IAttributeDef[];
  ALL_API_CONFIG_LIST_INPUT: IAttributeDef[];
  ADD_API_CONFIG_INPUT: IAttributeDef[];
  CREATE_PERMISSION_INPUT: IAttributeDef[];
  DETAILS_API_CONFIG_INPUT: IAttributeDef[];
  ADD_ROLE_INPUT: IAttributeDef[];
}

const AttributesConfig: IAttributesConfig = {
  ALL_USERS_FILTERS_INPUT: [
    {
      attribute_id: 101,
      name: 'organization_keys',
      ui_label: 'Organization name',
      ui_type: 'select',
      value_type: 'text',
      ui_rank: 101,
      status: 'published',
      class: 'all-users',
      is_visible: true,
      is_mandatory: false,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 102,
      name: 'business_unit_ids',
      ui_label: 'BU tagging',
      ui_type: 'select',
      value_type: 'text',
      ui_rank: 102,
      status: 'published',
      class: 'all-users',
      is_visible: false,
      is_mandatory: false,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    }
  ],
  ADD_USER_INPUT: [
    {
      attribute_id: 1,
      name: 'name',
      ui_label: 'User name',
      ui_type: 'string',
      value_type: 'text',
      ui_rank: 1,
      status: 'published',
      class: 'add-user',
      placeholder: 'Please enter Username',
      max_length: 50,
      is_visible: true,
      is_mandatory: true,
      is_editable: false,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 2,
      name: 'email_id',
      ui_label: 'Email ID',
      ui_type: 'string',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'add-user',
      placeholder: 'Please enter a valid email address',
      is_visible: true,
      is_mandatory: true,
      is_editable: false,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 3,
      name: 'user_type',
      ui_label: 'User type',
      ui_type: 'select',
      value_type: 'text',
      ui_rank: 3,
      status: 'published',
      class: 'add-user',
      options: null,
      placeholder: 'Please select User type',
      disable_clearable: true,
      is_visible: true,
      is_mandatory: true,
      is_editable: false,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {}
    },
    {
      attribute_id: 4,
      name: 'organization_keys',
      ui_label: 'Organization',
      ui_type: 'select',
      value_type: 'text',
      ui_rank: 4,
      status: 'published',
      class: 'add-user',
      options: null,
      placeholder: 'Please select organisation',
      disable_clearable: true,
      is_visible: true,
      is_mandatory: true,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {}
    },
    {
      attribute_id: 5,
      name: 'manager_user_key',
      ui_label: 'Manager name',
      ui_type: 'select',
      value_type: 'text',
      ui_rank: 4,
      status: 'published',
      class: 'add-user',
      options: null,
      placeholder: 'Please select Manager',
      disable_clearable: true,
      is_visible: true,
      is_mandatory: false,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {}
    },
    {
      attribute_id: 6,
      name: 'mobile',
      ui_label: 'Mobile number',
      ui_type: 'integer',
      value_type: 'number',
      ui_rank: 5,
      status: 'published',
      class: 'add-user',
      placeholder: 'Please enter Mobile number',
      max_length: 10,
      is_visible: true,
      is_mandatory: true,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    }
  ],
  USER_DETAILS: [],
  ROLE_DETAILS: [
    {
      attribute_id: 1,
      name: 'application',
      ui_label: 'Application',
      ui_type: 'string',
      value_type: 'text',
      ui_rank: 1,
      status: 'published',
      class: 'add-user',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: false,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 2,
      name: 'role',
      ui_label: 'Role name',
      ui_type: 'string',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'add-user',
      placeholder: '',
      max_length: 50,
      is_visible: true,
      is_mandatory: true,
      is_editable: false,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 3,
      name: 'permissions',
      ui_label: 'Permissions',
      ui_type: 'multi_select_chips',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'add-user',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    }
  ],
  ALL_API_CONFIG_LIST_INPUT: [
    {
      attribute_id: 1,
      name: 'applicationKey',
      ui_label: 'Application',
      ui_type: 'select',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    }
  ],
  ADD_API_CONFIG_INPUT: [
    {
      attribute_id: 2,
      name: 'name',
      ui_label: 'Name',
      ui_type: 'string',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 1,
      name: 'method',
      ui_label: 'HTTP Method',
      ui_type: 'select',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: [
        { value: 'get', label: 'GET' },
        { value: 'post', label: 'POST' },
        { value: 'put', label: 'PUT' },
        { value: 'delete', label: 'DELETE' },
        { value: 'patch', label: 'PATCH' },
        { value: 'options', label: 'OPTIONS' },
        { value: 'head', label: 'HEAD' }
      ]
    },
    {
      attribute_id: 3,
      name: 'uri',
      ui_label: 'URI',
      ui_type: 'string',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 4,
      name: 'version',
      ui_label: 'Version',
      ui_type: 'integer',
      value_type: 'number',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: false,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 5,
      name: 'is_authorized',
      ui_label: 'Is authorized',
      ui_type: 'select',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ]
    },
    {
      attribute_id: 6,
      name: 'preview_mode',
      ui_label: 'Preview mode',
      ui_type: 'select',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: false,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ]
    },
    {
      attribute_id: 7,
      name: 'policy_path',
      ui_label: 'Policy path',
      ui_type: 'string',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: false,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 9,
      name: 'permission_keys',
      ui_label: 'Permissions',
      ui_type: 'multi_select',
      value_type: 'array',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: false,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    }
  ],
  CREATE_PERMISSION_INPUT: [
    {
      attribute_id: 1,
      name: 'ui_label',
      ui_label: 'UI label',
      ui_type: 'string',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 7,
      name: 'description',
      ui_label: 'Description',
      ui_type: 'string',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    }
  ],
  DETAILS_API_CONFIG_INPUT: [
    {
      attribute_id: 2,
      name: 'name',
      ui_label: 'Name',
      ui_type: 'string',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 1,
      name: 'method',
      ui_label: 'HTTP Method',
      ui_type: 'select',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: false,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: [
        { value: 'get', label: 'GET' },
        { value: 'post', label: 'POST' },
        { value: 'put', label: 'PUT' },
        { value: 'delete', label: 'DELETE' },
        { value: 'patch', label: 'PATCH' },
        { value: 'options', label: 'OPTIONS' },
        { value: 'head', label: 'HEAD' }
      ]
    },
    {
      attribute_id: 3,
      name: 'uri',
      ui_label: 'URI',
      ui_type: 'string',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: false,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 4,
      name: 'version',
      ui_label: 'Version',
      ui_type: 'integer',
      value_type: 'number',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: false,
      is_editable: false,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 5,
      name: 'is_authorized',
      ui_label: 'Is authorized',
      ui_type: 'select',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ]
    },
    {
      attribute_id: 6,
      name: 'preview_mode',
      ui_label: 'Preview mode',
      ui_type: 'select',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: false,
      is_editable: false,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ]
    },
    {
      attribute_id: 7,
      name: 'policy_path',
      ui_label: 'Policy path',
      ui_type: 'string',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: false,
      is_editable: false,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 9,
      name: 'permissions',
      ui_label: 'Permissions',
      ui_type: 'multi_select',
      value_type: 'array',
      ui_rank: 2,
      status: 'published',
      class: 'api-config',
      placeholder: '',
      is_visible: true,
      is_mandatory: false,
      is_editable: true,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    }
  ],
  ADD_ROLE_INPUT: [
    {
      attribute_id: 1,
      name: 'organization',
      ui_label: 'Organization',
      ui_type: 'select',
      value_type: 'text',
      ui_rank: 1,
      status: 'published',
      class: 'add-role',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: false,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 2,
      name: 'application',
      ui_label: 'Application',
      ui_type: 'select',
      value_type: 'text',
      ui_rank: 2,
      status: 'published',
      class: 'add-role',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: false,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 3,
      name: 'ui_label',
      ui_label: 'Role name',
      ui_type: 'string',
      value_type: 'text',
      ui_rank: 3,
      status: 'published',
      class: 'add-role',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: false,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 4,
      name: 'module',
      ui_label: 'Module',
      ui_type: 'select',
      value_type: 'text',
      ui_rank: 4,
      status: 'published',
      class: 'add-role',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: false,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: [
        { value: 'category', label: 'Category' },
        { value: 'entity', label: 'Entity' },
        { value: 'product', label: 'Product' },
        { value: 'variant', label: 'Variant' },
        { value: 'vendor-pan', label: 'Vendor pan' },
        { value: 'vendor-gst', label: 'Vendor gst' },
        { value: 'vendor-location', label: 'Vendor location' },
        { value: 'vendor-legal', label: 'Vendor legal' },
        { value: 'uam', label: 'UAM' }
      ]
    },
    {
      attribute_id: 5,
      name: 'role_type',
      ui_label: 'Role type',
      ui_type: 'select',
      value_type: 'text',
      ui_rank: 5,
      status: 'published',
      class: 'add-role',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: false,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 6,
      name: 'description',
      ui_label: 'Description',
      ui_type: 'string',
      value_type: 'text',
      ui_rank: 6,
      status: 'published',
      class: 'add-role',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: false,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    },
    {
      attribute_id: 7,
      name: 'permission_key',
      ui_label: 'Permissions',
      ui_type: 'multi_select',
      value_type: 'array',
      ui_rank: 7,
      status: 'published',
      class: 'add-role',
      placeholder: '',
      is_visible: true,
      is_mandatory: true,
      is_editable: false,
      is_disabled: false,
      is_searchable: true,
      select_source_type: null,
      default_value: null,
      override_config: {},
      options: null
    }
  ]
};
// Assign `USER_DETAILS` after `ADD_USER_INPUT` is defined
AttributesConfig.USER_DETAILS = AttributesConfig.ADD_USER_INPUT;

export default AttributesConfig;
