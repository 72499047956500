import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import LocalStorage from "@/src/utils/LocalStorage";

const AuthMiddleware = () => {
  const userData = LocalStorage.getItem("userData");
  const location = useLocation();
  const navigate = useNavigate(); // Use the navigate hook

  useEffect(() => {
    const checkAuthentication = async () => {
      if (!userData?.token) {
        // If not authenticated, redirect to the login page
        navigate(
          `/login?redirectTo=${encodeURIComponent(
            location.pathname + location.search
          )}`
        );
      }
    };

    checkAuthentication(); // Check authentication on every render
  }, [userData, location, navigate]); // Include navigate in the dependency array

  if (userData) {
    // If authenticated, render the protected route
    return <Outlet />;
  } else {
    // While checking authentication, render nothing to prevent rendering the protected content before the redirect happens
    return null;
  }
};

export default AuthMiddleware;
