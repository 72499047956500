import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { DataGrid, GridPaginationModel } from '@mui/x-data-grid';
import { Box, Button, CircularProgress, Grid2, Typography } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

import { RootState, store } from '@/src/datastore/store';
import allUsersService from '@/src/services/all-users/allUsersService';
import UseNavigation from '@/src/hooks/useNavigation';
import CustomTablePaginationActions from '@/src/components/common/CustomTablePaginationActions';
import NoAuthorization from '@/src/components/common/NoAuthorization';
import DynamicInput from '@/src/components/common/DynamicInput';
import AttributesConfig, { IAttributeDef } from '@/src/config/AttributesConfig';
import AppConfig from '@/src/config/AppConfig';
import lightTheme from '@/src/styles/themes/lightTheme';
import {
  IAttributeOptions,
  ILooseObject,
  INPUT_FIELD_TYPE_MAP,
  INPUT_TYPES_ENUM,
  IPagination,
  UI_TYPE_INPUT_TYPE_MAP
} from '@/src/types/common.interface';

const Users = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingOrganizationList, setIsLoadingOrganizationList] = useState<boolean>(false);
  const [tableDetails, setTableDetails] = useState<ILooseObject>({
    columns: [],
    rows: []
  });
  const [paginationData, setPaginationData] = useState<IPagination>({
    page: 0,
    size: 20
  });
  const [pageRowCount, setPageRowCount] = useState<number>(0);
  const [extraFieldConfig, setExtraFieldConfig] = useState<ILooseObject>({});

  const navigate = UseNavigation();

  const {
    control,
    formState: { dirtyFields, errors },
    watch,
    reset,
    clearErrors
  } = useForm({
    mode: 'onSubmit'
  });

  const watchedValues = watch(); // Reactive watch for form values

  const userOrgPermissions: ILooseObject = useSelector(
    (state: RootState) => state?.userDetails?.OptionsAndPermissions?.orgPermissions
  );
  const getUserPermissionsUserPage = () => {
    const organizationKey = watchedValues?.organization_keys;
    if (organizationKey) {
      return userOrgPermissions?.[organizationKey];
    }
    return Object.values(userOrgPermissions)?.reduce((acc: string[], permissions: string[]) => {
      return Array.from(new Set([...acc, ...permissions]));
    }, []);
  };

  const getAllUsers = async (paginationData: IPagination, additionalFilterData?: ILooseObject) => {
    try {
      setIsLoading(true);
      const results = await allUsersService.getAllUsersList({
        pagination: paginationData,
        filters: {
          ...additionalFilterData,
          user_type: 'all'
        }
      });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        setTableDetails({
          columns: [],
          rows: []
        });
        setPageRowCount(0);
      } else if (!results.error.code) {
        setTableDetails({
          columns: results.columns,
          rows: results.rows
        });
        setPageRowCount(results?.page?.totalElements);
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getAllOrganizationList = async () => {
    // this will return authorized organizations
    try {
      setIsLoadingOrganizationList(true);
      const collectionAuthorizedOrg = Object.entries(
        store.getState()?.userDetails?.OptionsAndPermissions?.orgPermissions as Record<
          string,
          string[]
        >
      )?.reduce((acc: string[], [key, value]: [string, string[]]) => {
        if (value?.includes('all_user_view')) {
          acc = [...acc, key];
        }
        return acc;
      }, []);
      const collectedOptions = Object.entries(
        store.getState()?.userDetails?.OptionsAndPermissions?.organizationsOptions as Record<
          string,
          IAttributeOptions
        >
      )?.reduce((acc: IAttributeOptions[], [key, value]: [string, IAttributeOptions]) => {
        if (collectionAuthorizedOrg?.includes(key)) {
          acc.push(value);
        }
        return acc;
      }, []);

      setExtraFieldConfig((preExtraConfig: ILooseObject) => ({
        ...preExtraConfig,
        organization_keys: {
          ...preExtraConfig?.organization_keys,
          options: [...collectedOptions]
        }
      }));
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsLoadingOrganizationList(false);
    }
  };

  const getAvailableOptions = (attributeField: ILooseObject, extraFieldConfig?: ILooseObject) => {
    const availableOptions =
      extraFieldConfig?.[attributeField?.name]?.options ?? attributeField?.options ?? [];
    return availableOptions;
  };

  const handlePageChange = (data: GridPaginationModel) => {
    setPaginationData({
      page: data?.page,
      size: data?.pageSize
    });
  };

  useEffect(() => {
    getAllOrganizationList();
  }, []);

  useEffect(() => {
    setPageRowCount((prevPageRowCount: number) => pageRowCount ?? prevPageRowCount);
  }, [pageRowCount, setPageRowCount]);

  useEffect(() => {
    const userPermissions = getUserPermissionsUserPage();
    if (userPermissions?.includes('all_user_view')) {
      const filterInputData = Object.keys(watchedValues)?.reduce(
        (acc: ILooseObject, currentKey: string) => {
          if (dirtyFields?.[currentKey]) {
            acc[currentKey] = watchedValues?.[currentKey];
          }
          return acc;
        },
        {}
      );
      if (Object.keys(filterInputData)?.length > 0) {
        getAllUsers(paginationData, filterInputData);
      } else {
        getAllUsers(paginationData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationData, JSON.stringify(watchedValues)]);

  if (!getUserPermissionsUserPage()?.includes('all_user_view')) {
    return <NoAuthorization />;
  }

  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" color="primary.dark">
          All users
        </Typography>
        {getUserPermissionsUserPage()?.includes('user_create') && (
          <Button
            variant="outlined"
            startIcon={<PersonAddAltIcon />}
            size="small"
            sx={{ textTransform: 'none' }}
            onClick={() => {
              navigate.navigateTo('/users/add-user');
            }}>
            Add user
          </Button>
        )}
      </Box>
      <Box
        border={1}
        borderColor={lightTheme.palette.grey[300]}
        borderRadius={3}
        sx={{ p: 2, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
        {isLoadingOrganizationList ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography variant="body2" fontWeight={600}>
              Filter by:
            </Typography>
            <Grid2
              container
              component="form"
              display="flex"
              flexDirection="row"
              columnGap={2}
              alignItems="center"
              justifyContent="space-between"
              width="100%">
              {AttributesConfig.ALL_USERS_FILTERS_INPUT?.map((attributeField: IAttributeDef) => {
                const type = UI_TYPE_INPUT_TYPE_MAP[attributeField?.ui_type?.toUpperCase()];
                const inputType: INPUT_TYPES_ENUM =
                  INPUT_FIELD_TYPE_MAP[attributeField?.value_type?.toUpperCase()];

                const isRequired: boolean = !!attributeField?.is_mandatory;
                const isVisible: boolean = !!attributeField?.is_visible;
                if (!isVisible) {
                  return null;
                }
                const availableOptions = getAvailableOptions(attributeField, extraFieldConfig);

                return (
                  <Grid2
                    display="flex"
                    flexDirection="column"
                    rowGap={0.5}
                    key={attributeField?.attribute_id}
                    size={{ xs: 4 }}>
                    <Typography variant="body2" fontWeight={600}>
                      {attributeField?.ui_label}
                    </Typography>
                    <Controller
                      name={attributeField.name as string}
                      control={control}
                      render={({ field: { onChange, onBlur, value, name } }) => (
                        <DynamicInput
                          id={attributeField?.attribute_id}
                          label=""
                          type={type}
                          inputType={inputType}
                          required={isRequired}
                          value={value ?? ''}
                          disabled={false}
                          options={availableOptions}
                          size="small"
                          helperText={(errors[name]?.message as string) ?? ' '}
                          hasError={!!(errors[name]?.message as string)}
                          onChange={onChange}
                          onBlur={onBlur}
                          disableClearable={true}
                        />
                      )}
                    />
                  </Grid2>
                );
              })}
              <Button
                variant="outlined"
                size="small"
                sx={{ textTransform: 'none' }}
                onClick={() => {
                  reset();
                  clearErrors();
                }}
                disabled={!(dirtyFields && Object.keys(dirtyFields)?.length > 0)}>
                Reset
              </Button>
            </Grid2>
          </>
        )}
      </Box>
      <DataGrid
        getRowId={(row) => row.user_key}
        rows={tableDetails.rows}
        columns={tableDetails.columns}
        disableRowSelectionOnClick
        disableColumnSelector
        disableColumnMenu
        disableDensitySelector
        getRowHeight={() => 'auto'}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            textOverflow: 'clip',
            whiteSpace: 'break-spaces',
            lineHeight: 1.15,
            fontWeight: 600
          },
          '& .MuiDataGrid-row': {
            minHeight: '52px !important'
          },
          '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
          },
          '& .MuiDataGrid-main': {
            overflow: 'unset'
          },
          '& .MuiDataGrid-columnHeaders': {
            position: 'sticky',
            top: 63,
            zIndex: 1
          },
          '& .MuiDataGrid-container--top': {
            zIndex: 1
          },
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: lightTheme.palette.grey[100]
          }
        }}
        slotProps={{
          pagination: {
            ActionsComponent: CustomTablePaginationActions
          }
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 20, page: 0 }
          }
        }}
        paginationMode="server"
        loading={isLoading}
        rowCount={pageRowCount}
        onPaginationModelChange={handlePageChange}
        pageSizeOptions={[20, 50, 100]}
      />
    </Box>
  );
};

export default Users;
