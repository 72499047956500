import { enqueueSnackbar } from 'notistack';
import { Box, Button } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import ApiClient from '@/src/utils/ApiClient';
import UseNavigation from '@/src/hooks/useNavigation';
import { IApiClientResponse, ILooseObject } from '@/src/types/common.interface';
import { store } from '@/src/datastore/store';
import AppConfig from '@/src/config/AppConfig';
import TableHeadersConfig from '@/src/config/TableHeadersConfig';
import AllApiConfig, { ALL_API_CONFIG_URLS_ENUM } from '@/src/config/api_config/allApiConfig';

export interface IApiConfigActions {
  dispatchConfigRowData: (rowData: ILooseObject) => void;
}

export interface IGetApiConfigListResult {
  rows: ILooseObject[];
  columns: GridColDef[];
  error: IApiClientResponse['error'];
}

export interface IGetApiConfigListParams {
  applicationKey: string;
  actions: IApiConfigActions;
}

export interface ICreateNewApiConfigParams {
  applicationKey: string;
  payload: ILooseObject;
}

export interface ICreateNewPermissionParams {
  payload: ILooseObject;
  applicationKey: string;
}

export interface IUpdateBasicConfigDetails {
  applicationKey: string;
  id: string;
  payload: ILooseObject;
}

export interface IUpdateAddConfigPermission {
  applicationKey: string;
  id: string;
  filters: ILooseObject;
}

const updateTableColumns = (headers: GridColDef[]): GridColDef[] => {
  return headers.map((header: GridColDef) => {
    if (typeof header === 'object' && header !== null) {
      const tableColumns: GridColDef = {
        ...header
      };
      if (header.field === 'permissions') {
        tableColumns.valueGetter = (value: ILooseObject[], row: ILooseObject) => {
          if (Array.isArray(row?.permissions) && row?.permissions?.length > 0) {
            return row.permissions
              .map((permission: ILooseObject) => permission?.ui_label ?? '-')
              ?.join(', ');
          }
          return '-';
        };
      } else if (header.field === 'method') {
        tableColumns.valueGetter = (value: ILooseObject[], row: ILooseObject) => {
          if (row?.method) {
            return row.method.toUpperCase();
          }
          return '-';
        };
      } else if (header.field === 'is_authorized') {
        tableColumns.renderCell = (params: GridRenderCellParams) => {
          const isAuthorized = params?.row?.is_authorized;
          if (typeof isAuthorized === 'boolean') {
            return (
              <Box component="div" sx={{ my: 1 }}>
                {isAuthorized ? <CheckIcon /> : <CloseIcon />}
              </Box>
            );
          }
          return '-';
        };
      } else if (header.field === 'preview_mode') {
        tableColumns.renderCell = (params: GridRenderCellParams) => {
          const isPreviewMode = params?.row?.preview_mode;
          if (typeof isPreviewMode === 'boolean') {
            return (
              <Box component="div" sx={{ my: 1 }}>
                {isPreviewMode ? <CheckIcon /> : <CloseIcon />}
              </Box>
            );
          }
          return '-';
        };
      }
      return tableColumns;
    } else {
      return {} as GridColDef;
    }
  });
};

const updateDefaultColumns = (applicationKey: string, actions: IApiConfigActions): GridColDef[] => {
  const defaultColumns: GridColDef[] = [];
  defaultColumns.push({
    field: 'Actions',
    headerName: 'Actions',
    sortable: false,
    filterable: false,
    flex: 1,
    minWidth: 150,
    renderCell: (params: GridRenderCellParams) => {
      const navigation = UseNavigation();
      return (
        <Box component="div" sx={{ my: 1 }}>
          <Button
            variant="text"
            size="small"
            endIcon={<NavigateNextIcon />}
            color={'primary'}
            sx={{ textTransform: 'none' }}
            onClick={() => {
              actions?.dispatchConfigRowData(params?.row);
              navigation.navigateTo(
                `/api-config/api-config-details?applicationKey=${applicationKey}`
              );
            }}>
            View details
          </Button>
        </Box>
      );
    }
  });
  return defaultColumns;
};

abstract class AllApiConfigService {
  public static async getApiConfigList(params: IGetApiConfigListParams) {
    const { applicationKey, actions } = params;
    const result: IGetApiConfigListResult = {
      rows: [],
      columns: [],
      error: {
        code: 0,
        message: ''
      }
    };
    try {
      const response = await ApiClient.makeRequest({
        url: AllApiConfig.GetApiConfigApiUrl({
          url: ALL_API_CONFIG_URLS_ENUM.GET_ALL_API_CONFIG_LIST,
          data: { applicationKey }
        }),
        method: 'GET'
      });

      if (response.data) {
        const tableColumns: GridColDef[] = updateTableColumns(
          TableHeadersConfig.ALL_API_CONFIG_LIST || []
        );
        const userPermissions = store.getState()?.userDetails?.permissions ?? [];
        const applicationKey = response.data?.data?.application_key;
        const defaultColumns: GridColDef[] =
          userPermissions?.includes('all_user_view') || userPermissions?.includes('api_config_view')
            ? updateDefaultColumns(applicationKey, actions)
            : [];
        result.columns = [...tableColumns, ...defaultColumns];
        result.rows = response.data?.data?.api_configs ?? [];
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }

  public static async createNewApiConfig(params: ICreateNewApiConfigParams) {
    const { applicationKey, payload } = params;

    const result: IApiClientResponse = {
      data: {},
      error: {
        code: 0,
        message: ''
      }
    };
    try {
      const response = await ApiClient.makeRequest({
        url: AllApiConfig.GetApiConfigApiUrl({
          url: ALL_API_CONFIG_URLS_ENUM.CREATE_NEW_API_CONFIG,
          data: { applicationKey }
        }),
        body: payload,
        method: 'POST'
      });

      if (response.data) {
        result.data = {
          ...response.data
        };
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }

  public static async createNewPermission(params: ICreateNewPermissionParams) {
    const { payload, applicationKey } = params;

    const result: IApiClientResponse = {
      data: {},
      error: {
        code: 0,
        message: ''
      }
    };
    try {
      const response = await ApiClient.makeRequest({
        url: AllApiConfig.GetApiConfigApiUrl({
          url: ALL_API_CONFIG_URLS_ENUM.CREATE_NEW_PERMISSION,
          data: { applicationKey }
        }),
        body: payload,
        method: 'POST'
      });

      if (response.data) {
        result.data = {
          ...response.data
        };
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }

  public static async updateApiConfigBasicDetails(params: IUpdateBasicConfigDetails) {
    const { applicationKey, id, payload } = params;

    const result: IApiClientResponse = {
      data: {},
      error: {
        code: 0,
        message: ''
      }
    };
    try {
      const response = await ApiClient.makeRequest({
        url: AllApiConfig.GetApiConfigApiUrl({
          url: ALL_API_CONFIG_URLS_ENUM.UPDATE_BASIC_CONFIG_DETAILS,
          data: { applicationKey, id }
        }),
        body: payload,
        method: 'PUT'
      });

      if (response.data) {
        result.data = {
          ...response.data
        };
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }

  public static async updateAddConfigPermission(params: IUpdateAddConfigPermission) {
    const { applicationKey, id, filters } = params;

    const result: IApiClientResponse = {
      data: {},
      error: {
        code: 0,
        message: ''
      }
    };
    try {
      const response = await ApiClient.makeRequest({
        url: AllApiConfig.GetApiConfigApiUrl({
          url: ALL_API_CONFIG_URLS_ENUM.UPDATE_ADD_PERMISSION_API_CONFIG,
          data: { applicationKey, id },
          filters
        }),
        method: 'PUT'
      });

      if (response.data) {
        result.data = {
          ...response.data
        };
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }

  public static async updateDeleteConfigPermission(params: IUpdateAddConfigPermission) {
    const { applicationKey, id, filters } = params;

    const result: IApiClientResponse = {
      data: {},
      error: {
        code: 0,
        message: ''
      }
    };
    try {
      const response = await ApiClient.makeRequest({
        url: AllApiConfig.GetApiConfigApiUrl({
          url: ALL_API_CONFIG_URLS_ENUM.UPDATE_DELETE_PERMISSION_API_CONFIG,
          data: { applicationKey, id },
          filters
        }),
        method: 'DELETE'
      });

      if (response.data) {
        result.data = {
          ...response.data
        };
      } else {
        result.error = {
          ...response.error
        };
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    return result;
  }
}

export default AllApiConfigService;
