/* eslint-disable no-unused-vars */
import { IGetApiRequestParams, ILooseObject } from '@/src/types/common.interface';
import AppUtils from '@/src/utils/AppUtils';

export enum ALL_API_CONFIG_URLS_ENUM {
  GET_ALL_API_CONFIG_LIST = 'GET_ALL_API_CONFIG_LIST',
  CREATE_NEW_API_CONFIG = 'CREATE_NEW_API_CONFIG',
  CREATE_NEW_PERMISSION = 'CREATE_NEW_PERMISSION',
  UPDATE_BASIC_CONFIG_DETAILS = 'UPDATE_BASIC_CONFIG_DETAILS',
  UPDATE_ADD_PERMISSION_API_CONFIG = 'UPDATE_ADD_PERMISSION_API_CONFIG',
  UPDATE_DELETE_PERMISSION_API_CONFIG = 'UPDATE_DELETE_PERMISSION_API_CONFIG'
}

type GET_API_CONFIG_API_URL_TYPE = IGetApiRequestParams & {
  url: ALL_API_CONFIG_URLS_ENUM;
  data?: ILooseObject;
};

abstract class AllApiConfig {
  public static GetApiConfigApiUrl({
    url,
    data,
    filters,
    pagination,
    sorting
  }: GET_API_CONFIG_API_URL_TYPE) {
    switch (url) {
      case ALL_API_CONFIG_URLS_ENUM.GET_ALL_API_CONFIG_LIST:
        return AppUtils.formatGetApiParameters(
          `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/application/${data?.applicationKey}/api_configs`,
          { pagination, sorting, filters }
        );

      case ALL_API_CONFIG_URLS_ENUM.CREATE_NEW_API_CONFIG:
        return `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/applications/${data?.applicationKey}/api_configs`;

      case ALL_API_CONFIG_URLS_ENUM.CREATE_NEW_PERMISSION:
        return `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/${data?.applicationKey}/permission`;

      case ALL_API_CONFIG_URLS_ENUM.UPDATE_BASIC_CONFIG_DETAILS:
        return `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/applications/${data?.applicationKey}/api_configs/${data?.id}`;

      case ALL_API_CONFIG_URLS_ENUM.UPDATE_ADD_PERMISSION_API_CONFIG:
        return AppUtils.formatGetApiParameters(
          `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/applications/${data?.applicationKey}/api_configs/${data?.id}/permissions`,
          { filters }
        );

      case ALL_API_CONFIG_URLS_ENUM.UPDATE_DELETE_PERMISSION_API_CONFIG:
        return AppUtils.formatGetApiParameters(
          `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/applications/${data?.applicationKey}/api_configs/${data?.id}/permissions`,
          { filters }
        );

      default:
        return '';
    }
  }
}

export default AllApiConfig;
