import {
  IGetApiRequestParams,
  ILooseObject,
} from "@/src/types/common.interface";

export enum COMMON_API_URLS_ENUM {
  COMMON_GET_USER_DATA = "COMMON_GET_USER_DATA",
}

type GET_COMMON_API_URL_TYPE = IGetApiRequestParams & {
  url: COMMON_API_URLS_ENUM;
  data?: ILooseObject;
};

abstract class CommonApiConfig {
  public static GetCommonApiUrl({ url }: GET_COMMON_API_URL_TYPE): string {
    if (url === COMMON_API_URLS_ENUM.COMMON_GET_USER_DATA) {
      return `${process.env.REACT_APP_CENTRAL_AUTH_API_BASE_URL}/v1/user/me`;
    }
    return "";
  }
}

export default CommonApiConfig;
