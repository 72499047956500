import CryptoJS from 'crypto-js';
import { enqueueSnackbar } from 'notistack';
import { configureStore, combineReducers, Action } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore, Transform } from 'redux-persist';

import userReducer from '@/src/datastore/userDetails';
import AppConfig from '@/src/config/AppConfig';
import apiConfigReducer from '@/src/datastore/apiConfig';

const encryptionKey = process.env.REACT_APP_CRYPT_SECRET_KEY || '';

// Transform for encrypting and decrypting state
const encryptTransform: Transform<any, any> = {
  in: (state) => {
    // Encrypt the state
    const encryptedState = CryptoJS.AES.encrypt(JSON.stringify(state), encryptionKey).toString();
    return encryptedState;
  },
  out: (state) => {
    try {
      // Decrypt the state
      const bytes = CryptoJS.AES.decrypt(state, encryptionKey);
      const decryptedState = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedState;
    } catch (error) {
      enqueueSnackbar('Decryption failed', {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
      return {};
    }
  }
};

// Combine all reducers
const appReducer = combineReducers({
  userDetails: userReducer,
  apiConfigData: apiConfigReducer
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: Action) => {
  if (action.type === 'RESET_STORE') {
    // Returning undefined to reset the state
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  transforms: [encryptTransform]
};

// Wrapping the rootReducer with persistReducer
const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer);

// Configure the store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/PURGE'], //these actions can't be serialized
        ignoredPaths: ['register']
      }
    })
});

export const persistor = persistStore(store);

export const clearReduxStore = () => {
  store.dispatch({ type: 'RESET_STORE' }); // Clear in-memory Redux state
  persistor.purge(); // Clear persisted state from storage
};

// Defining the RootState and AppDispatch types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
