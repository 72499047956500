import { VariantType } from 'notistack';

type ISnackbar_Auto_Hide_Durations = {
  [variant in VariantType]: number;
};

export interface INavigationMenu {
  id: number;
  label: string;
  route: string;
  applicableRoutes: string[];
  icon?: string;
  ActiveIcon?: string;
  InActiveIcon?: string;
  requiredPermission: string;
  permissionLevel?: 'organization' | 'application';
}

type AppConfigType = {
  APP_DISPLAY_NAME: string;
  SNACKBAR_AUTO_HIDE_DURATIONS: ISnackbar_Auto_Hide_Durations;
  NAVIGATION_MENUS: INavigationMenu[];
};

const AppConfig: AppConfigType = {
  APP_DISPLAY_NAME: 'Central Access Management',
  SNACKBAR_AUTO_HIDE_DURATIONS: {
    success: 3000,
    error: 5000,
    warning: 4000,
    info: 3000,
    default: 3000
  },
  NAVIGATION_MENUS: [
    {
      id: 1,
      label: 'All users',
      route: '/users',
      applicableRoutes: ['/users', '/users/user-details', '/users/add-user'],
      icon: 'users',
      requiredPermission: 'all_user_view',
      permissionLevel: 'organization'
    },
    {
      id: 2,
      label: 'All roles',
      route: '/roles',
      applicableRoutes: ['/roles', '/roles/role-details', '/roles/add-role'],
      ActiveIcon: 'activeRoles',
      InActiveIcon: 'inActiveRoles',
      requiredPermission: 'all_role_view',
      permissionLevel: 'application'
    },
    {
      id: 3,
      label: 'API config',
      route: '/api-config',
      applicableRoutes: [
        '/api-config',
        '/api-config/add-api-config',
        '/api-config/api-config-details'
      ],
      icon: 'config',
      requiredPermission: 'api_config_view',
      permissionLevel: 'application'
    }
  ]
};

export default AppConfig;
