import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Box, Grid2, Typography } from '@mui/material';

import { saveApiConfig } from '@/src/datastore/apiConfig';
import { RootState } from '@/src/datastore/store';
import AppMessages from '@/src/config/message_config/AppMessages';
import AppConfig from '@/src/config/AppConfig';
import AttributesConfig, { IAttributeDef } from '@/src/config/AttributesConfig';
import DynamicBreadCrumbs from '@/src/components/common/DynamicBreadCrumbs';
import DynamicInput from '@/src/components/common/DynamicInput';
import NoAuthorization from '@/src/components/common/NoAuthorization';
import AllApiConfigService from '@/src/services/api-config/allApiConfigService';
import {
  ILooseObject,
  INPUT_FIELD_TYPE_MAP,
  INPUT_TYPES_ENUM,
  UI_TYPE_INPUT_TYPE_MAP
} from '@/src/types/common.interface';
import SchemaValidations from '@/src/utils/SchemaValidations';
import AllRolesService from '@/src/services/all-roles/allRolesService';
import lightTheme from '@/src/styles/themes/lightTheme';

const ApiConfigDetails = () => {
  const [isOptionsLoading, setIsOptionsLoading] = useState<ILooseObject>({});
  const [inputOptions, setInputOptions] = useState<ILooseObject>({});
  const [defaultValues, setDefaultValues] = useState<ILooseObject>({});
  const [allPermissionsList, setAllPermissionsList] = useState<ILooseObject[]>([]);
  const [permissionUiMode, setPermissionUiMode] = useState<'VIEW' | 'EDIT'>('VIEW');
  const [configUiMode, setConfigUiMode] = useState<'VIEW' | 'EDIT'>('VIEW');

  const apiConfigData = useSelector((state: RootState) => state?.apiConfigData?.rowData);
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);
  const applicationKey: string = searchParams?.get('applicationKey') ?? '';

  const configBasicSchema = SchemaValidations.AddApiConfig(
    AttributesConfig.DETAILS_API_CONFIG_INPUT
  );
  const configPermissionSchema = SchemaValidations.AddApiConfig(
    AttributesConfig.DETAILS_API_CONFIG_INPUT?.filter(
      (attribute: IAttributeDef) => attribute?.name === 'permissions'
    )
  );

  const {
    control: detailsConfigControl,
    handleSubmit: handleUpdateConfigSubmit,
    formState: {
      errors: detailSConfigErrors,
      isSubmitting: isDetailSConfigSubmitting,
      isDirty: isDetailSConfigDirty
    },
    clearErrors: clearDetailSConfigErrors,
    reset: resetDetailSConfig
  } = useForm({
    defaultValues: { ...defaultValues },
    resolver: yupResolver(configBasicSchema, { abortEarly: false }),
    mode: 'onBlur'
  });

  const {
    control: permissionsControl,
    handleSubmit: handlePermissionsSubmit,
    formState: {
      errors: permissionsErrors,
      isSubmitting: isPermissionsSubmitting,
      isDirty: isPermissionsDirty
    },
    clearErrors: clearPermissionsErrors,
    reset: resetPermissions
  } = useForm({
    defaultValues: { permissions: defaultValues?.permissions },
    resolver: yupResolver(configPermissionSchema, { abortEarly: false }),
    mode: 'onBlur'
  });

  const userAppPermissions: ILooseObject = useSelector(
    (state: RootState) => state?.userDetails?.OptionsAndPermissions?.appPermissions
  );

  const getAppPermissions = (applicationKey: string) => {
    //filtering permissions who have users applications
    return Object.entries(userAppPermissions as Record<string, string[]>)?.reduce(
      (acc: string[], [key, permissions]: [string, string[]]) => {
        if (key === applicationKey) {
          return Array.from(new Set([...acc, ...permissions]));
        }
        return acc;
      },
      []
    );
  };

  const processDefaultValue = (configDetails: ILooseObject) => {
    return AttributesConfig.DETAILS_API_CONFIG_INPUT?.reduce(
      (collection: ILooseObject, attribute: IAttributeDef) => {
        if (attribute?.name === 'permissions') {
          const permissions = configDetails?.permissions?.map(
            (permission: ILooseObject) => permission?.permission_key
          );
          collection[attribute?.name] = permissions;
        } else {
          collection[attribute?.name] = configDetails[attribute?.name];
        }
        return collection;
      },
      {}
    );
  };

  const getAllPermissions = async (applicationKey: string) => {
    try {
      setIsOptionsLoading((prev: ILooseObject) => ({ ...prev, permissionKeys: true }));
      const results = await AllRolesService.getAllPermissions({ applicationKey });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } else if (!results.error.code) {
        setAllPermissionsList(results?.data as []);
        const permissions =
          results?.data?.map((app: ILooseObject) => ({
            label: app?.ui_label ?? '-',
            value: app?.permission_key ?? '-'
          })) ?? [];
        setInputOptions((prev: ILooseObject) => ({
          ...prev,
          permissions
        }));
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsOptionsLoading((prev: ILooseObject) => ({ ...prev, permissionKeys: false }));
    }
  };

  const addPermissionInConfig = async (
    permissionKeys: string[],
    applicationKey: string,
    id: string
  ) => {
    try {
      const results = await AllApiConfigService.updateAddConfigPermission({
        applicationKey,
        id,
        filters: { permission_keys: permissionKeys?.join(',') }
      });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } else if (!results.error.code) {
        enqueueSnackbar(AppMessages.Success.apiConfigUpdated, {
          variant: 'success',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.success
        });
      }

      dispatch(saveApiConfig({ rowData: results?.data?.data }));
      return results;
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsOptionsLoading((prev: ILooseObject) => ({ ...prev, permissionKeys: false }));
    }
  };

  const deletePermissionInConfig = async (
    permissionKeys: string[],
    applicationKey: string,
    id: string
  ) => {
    try {
      const results = await AllApiConfigService.updateDeleteConfigPermission({
        applicationKey,
        id,
        filters: { permissionKeys: permissionKeys?.join(',') }
      });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } else if (!results.error.code) {
        enqueueSnackbar(AppMessages.Success.apiConfigUpdated, {
          variant: 'success',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.success
        });
      }
      dispatch(saveApiConfig({ rowData: results?.data?.data }));
      return results;
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsOptionsLoading((prev: ILooseObject) => ({ ...prev, permissionKeys: false }));
    }
  };

  const updateBasicConfigDetails = async (data: ILooseObject) => {
    try {
      const payload = data;
      const results = await AllApiConfigService.updateApiConfigBasicDetails({
        applicationKey: applicationKey,
        payload,
        id: apiConfigData?.id
      });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } else if (!results.error.code) {
        enqueueSnackbar(AppMessages.Success.apiConfigUpdated, {
          variant: 'success',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.success
        });

        dispatch(saveApiConfig({ rowData: results?.data?.data }));
        setDefaultValues((prev) => ({
          ...prev,
          ...(results?.data?.data ?? {})
        }));
        resetDetailSConfig({
          ...defaultValues,
          ...(results?.data?.data ?? {})
        });
        setConfigUiMode('VIEW');
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
  };

  const handleUpdateAndDeletePermissions = async (data: ILooseObject) => {
    const oldPermissions = defaultValues.permissions || [];
    const newPermissions = data.permissions || [];

    const permissionsToAdd = newPermissions.filter(
      (permission: string) => !oldPermissions.includes(permission)
    );
    const permissionsToDelete = oldPermissions.filter(
      (permission: string) => !newPermissions.includes(permission)
    );

    let success = true;

    if (permissionsToAdd.length > 0) {
      const addResponse = await addPermissionInConfig(
        permissionsToAdd,
        applicationKey,
        apiConfigData.id
      );
      if (addResponse?.error?.message) {
        success = false;
      }
    }

    if (permissionsToDelete.length > 0) {
      const deleteResponse = await deletePermissionInConfig(
        permissionsToDelete,
        applicationKey,
        apiConfigData.id
      );
      if (deleteResponse?.error?.message) {
        success = false;
      }
    }

    if (success) {
      setDefaultValues((prev) => ({
        ...prev,
        permissions: data.permissions
      }));
      resetPermissions({ permissions: data.permissions });
    }

    if (success) {
      setPermissionUiMode('VIEW');
    } else {
      setPermissionUiMode('EDIT');
    }
    // Add your logic here
  };

  const handlePermissionEdit = () => {
    setPermissionUiMode('EDIT');
    if (allPermissionsList?.length === 0 && applicationKey) {
      getAllPermissions(applicationKey);
    }
  };

  const handleResetConfigDetails = () => {
    setConfigUiMode('VIEW');
    resetDetailSConfig();
  };

  const handleResetPermissionDetails = () => {
    setPermissionUiMode('VIEW');
    resetPermissions();
  };

  useEffect(() => {
    if (apiConfigData) {
      const permissionOptions = apiConfigData?.permissions?.map((permission: ILooseObject) => ({
        value: permission?.permission_key,
        label: permission?.ui_label
      }));

      setInputOptions((prev: ILooseObject) => ({
        ...prev,
        permissions: permissionOptions
      }));
      const configValues: ILooseObject = processDefaultValue(apiConfigData);
      setDefaultValues(configValues);
      resetDetailSConfig(configValues);
      resetPermissions({ permissions: configValues?.permissions });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiConfigData, resetDetailSConfig]);

  if (!getAppPermissions(applicationKey)?.includes('api_config_view')) {
    return <NoAuthorization />;
  }

  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      <DynamicBreadCrumbs
        breadCrumbsDetails={[
          { key: 1, name: 'All API config', redirectPath: '/api-config' },
          { key: 2, name: 'Details API config' }
        ]}
      />
      <Typography variant="h5" sx={{ my: 2 }} color="primary.dark">
        Details api config
      </Typography>
      <Box component="form" onSubmit={handleUpdateConfigSubmit(updateBasicConfigDetails)}>
        <Box
          border={1}
          borderColor={lightTheme.palette.grey[300]}
          borderRadius={3}
          sx={{ p: 2, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
          <Grid2 container columnSpacing={10}>
            {AttributesConfig.DETAILS_API_CONFIG_INPUT?.filter(
              (attribute: IAttributeDef) => attribute?.name !== 'permissions'
            )?.map((attribute: IAttributeDef) => {
              const inputType = UI_TYPE_INPUT_TYPE_MAP[attribute?.ui_type?.toUpperCase()];
              const options = attribute?.options ?? inputOptions[attribute?.name] ?? [];
              const inputFieldType: INPUT_TYPES_ENUM =
                INPUT_FIELD_TYPE_MAP[
                  typeof attribute?.value_type === 'string'
                    ? attribute?.value_type?.toUpperCase()
                    : ''
                ];
              const disabled = !attribute?.is_editable || configUiMode === 'VIEW';
              const isVisible: boolean = !!attribute?.is_visible;
              if (!isVisible) {
                return null;
              }
              const isRequired = attribute?.is_mandatory;

              return (
                <Grid2 container size={{ xs: 6 }} key={attribute?.attribute_id} alignItems="center">
                  {/* UI label */}
                  <Grid2 size={{ xs: 4 }} display="flex" alignItems="center">
                    <Box sx={{ textAlign: 'left', pb: 4 }}>
                      <Typography>{`${attribute?.ui_label} ${isRequired ? '*' : ''}`}</Typography>
                    </Box>
                  </Grid2>
                  {/* DynamicInput */}
                  <Grid2 size={{ xs: 8 }}>
                    <Controller
                      name={attribute.name as string}
                      control={detailsConfigControl}
                      render={({ field: { onChange, onBlur, value, name } }) => {
                        return (
                          <DynamicInput
                            type={inputType}
                            id={attribute?.attribute_id}
                            label={attribute?.ui_label}
                            value={value}
                            hasError={!!(detailSConfigErrors?.[name]?.message as string)}
                            helperText={(detailSConfigErrors?.[name]?.message as string) ?? ' '}
                            options={options}
                            loading={isOptionsLoading?.[attribute?.name]}
                            maxLength={attribute?.max_length}
                            placeholder={attribute?.placeholder}
                            disableClearable={attribute?.disable_clearable}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                              onChange(e);
                              clearDetailSConfigErrors(name);
                            }}
                            onBlur={onBlur}
                            inputType={inputFieldType}
                            disabled={disabled}
                          />
                        );
                      }}
                    />
                  </Grid2>
                </Grid2>
              );
            })}
          </Grid2>
          <Box display="flex" alignSelf="flex-end">
            {configUiMode === 'VIEW'
              ? getAppPermissions(applicationKey)?.includes('api_config_edit') && (
                  <LoadingButton
                    size="small"
                    variant="outlined"
                    onClick={() => setConfigUiMode('EDIT')}
                    sx={{ textTransform: 'none' }}>
                    Edit config details
                  </LoadingButton>
                )
              : getAppPermissions(applicationKey)?.includes('api_config_edit') && (
                  <Box display="flex" columnGap={2}>
                    <LoadingButton
                      size="small"
                      variant="outlined"
                      sx={{ textTransform: 'none' }}
                      onClick={handleResetConfigDetails}>
                      Cancel
                    </LoadingButton>
                    <LoadingButton
                      size="small"
                      variant="contained"
                      type="submit"
                      loading={isDetailSConfigSubmitting}
                      disabled={
                        Object.keys(detailSConfigErrors).length > 0 || !isDetailSConfigDirty
                      }
                      sx={{ textTransform: 'none' }}>
                      Update
                    </LoadingButton>
                  </Box>
                )}
          </Box>
        </Box>
      </Box>

      <Box component="form" onSubmit={handlePermissionsSubmit(handleUpdateAndDeletePermissions)}>
        <Box
          border={1}
          borderColor={lightTheme.palette.grey[300]}
          borderRadius={3}
          sx={{ p: 2, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
          {AttributesConfig.DETAILS_API_CONFIG_INPUT?.filter(
            (attribute: IAttributeDef) => attribute?.name === 'permissions'
          )?.map((attribute: IAttributeDef) => {
            const inputType = UI_TYPE_INPUT_TYPE_MAP[attribute?.ui_type?.toUpperCase()];
            const options = attribute?.options ?? inputOptions[attribute?.name] ?? [];
            const inputFieldType: INPUT_TYPES_ENUM =
              INPUT_FIELD_TYPE_MAP[
                typeof attribute?.value_type === 'string'
                  ? attribute?.value_type?.toUpperCase()
                  : ''
              ];
            const disabled = !attribute?.is_editable || permissionUiMode === 'VIEW';
            const isVisible: boolean = !!attribute?.is_visible;
            if (!isVisible) {
              return null;
            }
            const isRequired = attribute?.is_mandatory;

            return (
              <Grid2 container size={{ xs: 12 }} key={attribute?.attribute_id} alignItems="center">
                <Grid2 size={{ xs: 2 }} display="flex" alignItems="center">
                  <Box sx={{ textAlign: 'left', pb: 4 }}>
                    <Typography>{`${attribute?.ui_label} ${isRequired ? '*' : ''}`}</Typography>
                  </Box>
                </Grid2>
                <Grid2 size={{ xs: 10 }}>
                  <Controller
                    name={attribute.name as string}
                    control={permissionsControl}
                    render={({ field: { onChange, onBlur, value, name } }) => {
                      return (
                        <DynamicInput
                          type={inputType}
                          id={attribute?.attribute_id}
                          label={attribute?.ui_label}
                          value={value}
                          hasError={!!(permissionsErrors?.[name]?.message as string)}
                          helperText={(permissionsErrors?.[name]?.message as string) ?? ' '}
                          options={options}
                          loading={isOptionsLoading?.[attribute?.name]}
                          maxLength={attribute?.max_length}
                          placeholder={attribute?.placeholder}
                          disableClearable={attribute?.disable_clearable}
                          onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            onChange(e);
                            clearPermissionsErrors(name);
                          }}
                          onBlur={onBlur}
                          inputType={inputFieldType}
                          disabled={disabled}
                        />
                      );
                    }}
                  />
                </Grid2>
              </Grid2>
            );
          })}
          <Box display="flex" alignSelf="flex-end">
            {permissionUiMode === 'VIEW'
              ? getAppPermissions(applicationKey)?.includes('api_config_edit') && (
                  <LoadingButton
                    size="small"
                    variant="outlined"
                    onClick={handlePermissionEdit}
                    sx={{ textTransform: 'none' }}>
                    Edit permissions
                  </LoadingButton>
                )
              : getAppPermissions(applicationKey)?.includes('api_config_edit') && (
                  <Box display="flex" columnGap={2}>
                    <LoadingButton
                      size="small"
                      variant="outlined"
                      sx={{ textTransform: 'none' }}
                      onClick={handleResetPermissionDetails}>
                      Cancel
                    </LoadingButton>
                    <LoadingButton
                      size="small"
                      variant="contained"
                      type="submit"
                      loading={isPermissionsSubmitting}
                      disabled={Object.keys(permissionsErrors).length > 0 || !isPermissionsDirty}
                      sx={{ textTransform: 'none' }}>
                      Update
                    </LoadingButton>
                  </Box>
                )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ApiConfigDetails;
