type errorAppMessageTypes = {
  missing_url: string;
  missing_method: string;
  unauthorized: string;
  forbidden: string;
  server_error: string;
  network_error: string;
  generic_error: string;
  access_denied: string;
  user_name_required: string;
  user_name_min_characters: string;
  user_type_required: string;
  organisation_required: string;
  email_required: string;
  mobile_number_required: string;
  invalid_email: string;
  invalid_mobile_number: string;
  empty_organization_keys: string;
};
type successAppMessageTypes = {
  user_added: string;
  delete_user: string;
  permission_added: string;
  ApiConfigAdded: string;
  apiConfigUpdated: string;
  roles_updated: string;
  role_added: string;
};
type warningAppMessageTypes = {};
type infoAppMessageTypes = {};

interface IAppMessages {
  Error: errorAppMessageTypes;
  Success: successAppMessageTypes;
  Warning: warningAppMessageTypes;
  Info: infoAppMessageTypes;
}

const AppMessages: IAppMessages = {
  Error: {
    missing_url: 'Url is required',
    missing_method: 'Method is required',
    unauthorized: 'Unauthorized',
    forbidden: 'Forbidden',
    server_error: 'Server error',
    network_error: 'Network error',
    generic_error: 'An unexpected error occurred',
    access_denied: 'Access denied',
    user_name_required: 'Username is required',
    user_name_min_characters: 'Name should be minimum 5 characters',
    user_type_required: 'User type selection is required',
    organisation_required: 'Organisation selection is required',
    email_required: 'Email is required',
    mobile_number_required: 'Mobile number is required',
    invalid_email: 'Enter a valid email address',
    invalid_mobile_number: 'Enter a valid 10-digit mobile number',
    empty_organization_keys: 'You are not currently associated with any organization'
  },
  Success: {
    user_added: 'User added successfully.',
    delete_user: 'User deleted successfully.',
    permission_added: 'Permission added successfully.',
    ApiConfigAdded: 'Api Config added successfully.',
    apiConfigUpdated: 'Api config updated successfully.',
    roles_updated: 'Roles updated successfully.',
    role_added: 'Role added successfully'
  },
  Warning: {},
  Info: {}
};

export default AppMessages;
