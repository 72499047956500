import CommonApiConfig, {
  COMMON_API_URLS_ENUM,
} from "@/src/config/api_config/common";
import { IApiClientResponse, ILooseObject } from "@/src/types/common.interface";
import ApiClient from "@/src/utils/ApiClient";

type authenticateUserParamsType = {
  token: string;
};

type authenticateUserResultType = {
  data: ILooseObject;
  error: {
    code: number;
    message: string;
  };
};

abstract class UserLoginService {
  public static async getUserData(params: authenticateUserParamsType) {
    const { token } = params;

    const result: authenticateUserResultType = {
      data: {},
      error: {
        code: 0,
        message: "",
      },
    };
    const response: IApiClientResponse = await ApiClient.makeRequest({
      url: CommonApiConfig.GetCommonApiUrl({
        url: COMMON_API_URLS_ENUM.COMMON_GET_USER_DATA,
      }),
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    if (response.data?.data) {
      delete response.data.data.password;
      result.data = { ...response.data.data, token };
    } else {
      result.error = { ...response.error };
    }
    return result;
  }
}

export default UserLoginService;
