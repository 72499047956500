import { SnackbarKey, useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

type NotistackCloseButtonProp = {
  snackbarKey: SnackbarKey;
};
const NotistackCloseButton = ({
  snackbarKey,
}: NotistackCloseButtonProp): JSX.Element => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton color="inherit" onClick={() => closeSnackbar(snackbarKey)}>
      <CloseIcon />
    </IconButton>
  );
};

export default NotistackCloseButton;
