import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import AppConfig from "@/src/config/AppConfig";
import { IApiClientResponse } from "@/src/types/common.interface";
import LocalStorage from "@/src/utils/LocalStorage";
import UserLoginService from "@/src/services/common/userLoginService";

const OAuth2Redirect = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const getUserData = useCallback(
    async (token: string) => {
      const results: IApiClientResponse = await UserLoginService.getUserData({
        token,
      });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: "error",
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error,
        });
        navigate("/login");
      } else {
        LocalStorage.setItem("userData", results.data);
        const redirectTo: string = location.pathname + location.search;
        //TODO: redirect on the existing path
        navigate("/");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, token]
  );

  useEffect(() => {
    if (token) {
      getUserData(token as string);
    } else {
      const accessToken = LocalStorage.getItem("token");
      if (accessToken) {
        getUserData(accessToken);
        LocalStorage.removeItem("token");
      }
    }
  }, [getUserData, token]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {/* TODO: Make the correct loading */}
      <CircularProgress />
    </Box>
  );
};

export default OAuth2Redirect;
