import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import GoogleIcon from "@/public/icons/GoogleIcon";
import OutlookIcon from "@/public/icons/OutlookIcon";

const currentOrigin: string =
  typeof window !== "undefined" && window.location.origin
    ? window.location.origin
    : "";

type LoginProps = {
  redirectTo: string;
};

const Login = ({ redirectTo }: LoginProps) => {
  const OAUTH2_REDIRECT_URI: string = encodeURIComponent(
    `${currentOrigin}/oauth2/redirect?redirectTo=${encodeURIComponent(
      redirectTo
    )}`
  );
  const OAUTH2_REDIRECT_OUTLOOK_URI: string = `${currentOrigin}/oauth2/redirect?redirectTo=${redirectTo}`;

  const GOOGLE_AUTH_URL: string = `${process.env.REACT_APP_AUTH_SERVICE_BASE_URL}/oauth2/authorize/google?redirect_uri=${OAUTH2_REDIRECT_URI}`;
  const OUTLOOK_AUTH_URL: string = `${process.env.REACT_APP_AUTH_SERVICE_BASE_URL}/auth/outlook-login?referer=${OAUTH2_REDIRECT_OUTLOOK_URI}`;

  return (
    <Box>
      <Link to={GOOGLE_AUTH_URL} title="Sign in with Google">
        <Button
          variant="outlined"
          startIcon={<GoogleIcon />}
          fullWidth
          size="large"
          sx={{ lineHeight: 1 }}
        >
          Sign in with Google
        </Button>
      </Link>
      <Link to={OUTLOOK_AUTH_URL} title="Sign in with Outlook">
        <Button
          variant="outlined"
          startIcon={<OutlookIcon />}
          fullWidth
          size="large"
          sx={{ lineHeight: 1, mt: 1 }}
        >
          Sign in with Outlook
        </Button>
      </Link>
    </Box>
  );
};

export default Login;
