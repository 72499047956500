import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Home = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      rowGap={1}
    >
      <Typography variant="button" fontWeight="bold">
        All users page
      </Typography>
    </Box>
  );
};

export default Home;
