type errorAppMessageTypes = {
  missing_url: string;
  missing_method: string;
  unauthorized: string;
  forbidden: string;
  server_error: string;
  network_error: string;
  generic_error: string;
  access_denied: string;
};
type successAppMessageTypes = {};
type warningAppMessageTypes = {};
type infoAppMessageTypes = {};

interface IAppMessages {
  Error: errorAppMessageTypes;
  Success: successAppMessageTypes;
  Warning: warningAppMessageTypes;
  Info: infoAppMessageTypes;
}

const AppMessages: IAppMessages = {
  Error: {
    missing_url: "Url is required",
    missing_method: "Method is required",
    unauthorized: "Unauthorized",
    forbidden: "Forbidden",
    server_error: "Server error",
    network_error: "Network error",
    generic_error: "An unexpected error occurred",
    access_denied: "Access denied",
  },
  Success: {},
  Warning: {},
  Info: {},
};

export default AppMessages;
