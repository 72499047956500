import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, CircularProgress, Grid2 } from '@mui/material';
import { DataGrid, GridRowParams, GridRowSelectionModel, GridRowId } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab';

import { RootState, store } from '@/src/datastore/store';
import AppConfig from '@/src/config/AppConfig';
import {
  IApiClientResponse,
  IAttributeOptions,
  ILooseObject,
  INPUT_FIELD_TYPE_MAP,
  INPUT_TYPES_ENUM,
  ListingTableDataStateType,
  UI_TYPE_INPUT_TYPE_MAP
} from '@/src/types/common.interface';
import AttributesConfig, { IAttributeDef } from '@/src/config/AttributesConfig';
import AppMessages from '@/src/config/message_config/AppMessages';
import SchemaValidations from '@/src/utils/SchemaValidations';
import lightTheme from '@/src/styles/themes/lightTheme';
import AllUsersService from '@/src/services/all-users/allUsersService';
import AllRolesService from '@/src/services/all-roles/allRolesService';
import DynamicBreadCrumbs from '@/src/components/common/DynamicBreadCrumbs';
import NoAuthorization from '@/src/components/common/NoAuthorization';
import DynamicInput from '@/src/components/common/DynamicInput';
import ConfirmDialog from '@/src/components/common/ConfirmDialog';

const UserDetails = () => {
  const [userDetails, setUserDetails] = useState<ILooseObject>({});
  const [defaultUsersValues, setDefaultUsersValues] = useState<ILooseObject>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDeleteUser, setIsLoadingDeleteUser] = useState<boolean>(false);
  const [inputOptions, setInputOptions] = useState<ILooseObject>({}); // contains all the options
  const [isOptionsLoading, setIsOptionsLoading] = useState<ILooseObject>({}); // contains options loading status
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);
  const [userUiMode, setUserUiMode] = useState<'VIEW' | 'EDIT'>('VIEW');
  const [applicationUiMode, setApplicationUiMode] = useState<'VIEW' | 'EDIT'>('VIEW');
  const [isLoadingRoles, setIsLoadingRoles] = useState<boolean>(false);
  const [roleSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [userAssignedRoleTable, setUserAssignedRoleTable] = useState<ListingTableDataStateType>({
    rows: [],
    columns: []
  });
  const [userAssignedRawTable, setUserAssignedRawTable] = useState<ListingTableDataStateType>({
    rows: [],
    columns: []
  });
  const [userDerivedRoleTable, setUserDerivedRoleTable] = useState<ListingTableDataStateType>({
    rows: [],
    columns: []
  });
  const [useDerivedRawTable, setUserDerivedRawTable] = useState<ListingTableDataStateType>({
    rows: [],
    columns: []
  });
  const [version, setVersion] = useState<number>(0);
  const [initialRoles, setInitialRoles] = useState<string[]>([]);
  const [userOrgWithAppKeys, setUserOrgWithAppKeys] = useState<ILooseObject>({});
  const [isEditEnabled, setIsEditEnabled] = useState<boolean>(false);
  const [commonAppKeys, setCommonAppKeys] = useState<string[]>([]);
  const [unSelectableRows, setUnSelectableRows] = useState<ILooseObject>([]);
  const [parentChildRelations, setParentChildRelations] = useState<ILooseObject>({});

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const userKey = searchParams.get('userKey');
  const organizationKey = searchParams.get('orgKey');
  const schema = SchemaValidations.AddUsers(AttributesConfig.USER_DETAILS);

  const {
    control: controlUserDetails,
    handleSubmit: handleSubmitUserDetails,
    formState: {
      errors: errorsUserDetails,
      isSubmitting: isSubmittingUserDetails,
      isDirty: isDirtyUserDetails
    },
    clearErrors: clearErrorsUserDetails,
    getValues: getValuesUserDetails,
    reset: resetUserDetails
  } = useForm({
    defaultValues: defaultUsersValues,
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: 'onBlur'
  });

  // applications and role form
  const {
    control: controlApplications,
    handleSubmit: handleSubmitApplications,
    formState: { isDirty: isDirtyApplications, isSubmitting: isSubmittingApplications },
    getValues: getValuesApplications,
    reset: resetApplications,
    watch
  } = useForm({
    defaultValues: { roles: [...roleSelectionModel] },
    mode: 'onBlur'
  });

  const watchedValues: ILooseObject = watch();
  const currentRoles = watch('roles'); // Track current role change

  const meApiData = useSelector((state: RootState) => state?.userDetails);
  const userOrgPermissions: ILooseObject = meApiData?.OptionsAndPermissions?.orgPermissions;

  const appKeysWithPermissions: ILooseObject = meApiData?.OptionsAndPermissions?.appPermissions;

  const permissionsNeededForEdit: string[] = ['user_add_role', 'all_role_view'];

  const validAppKeys: string[] = Object.keys(appKeysWithPermissions ?? {})?.filter(
    (appKey: string) => {
      return permissionsNeededForEdit?.every((permission: string) =>
        appKeysWithPermissions[appKey].includes(permission)
      );
    }
  );

  // requester org with app keys
  const requesterOrgWithAppKeys: ILooseObject = {};
  meApiData?.organizations?.forEach((organization: ILooseObject) => {
    if (organization?.organization_key) {
      requesterOrgWithAppKeys[organization?.organization_key] = organization?.applications
        ?.map((application: ILooseObject) => application?.application_key ?? '-')
        ?.filter((app: string) => validAppKeys?.includes(app));
    }
  });

  const getUserPermissionsUserPage = () => {
    if (organizationKey) {
      return organizationKey?.split(',')?.reduce((acc: string[], key: string) => {
        return Array.from(new Set([...acc, ...(userOrgPermissions?.[key] ?? [])]));
      }, []);
    }
    return [];
  };
  const isAuthorized: boolean =
    getUserPermissionsUserPage()?.includes('user_view') ||
    getUserPermissionsUserPage()?.includes('all_role_view');
  const getUserDetailsValues = getValuesUserDetails();
  const getApplicationsValues = getValuesApplications();

  const processDefaultUserData = (userData: ILooseObject, userAttributes: IAttributeDef[]) => {
    const defaultUserValuesCollection = userAttributes?.reduce(
      (collectedValues: ILooseObject, currentAttribute: IAttributeDef) => {
        if (currentAttribute?.name === 'organization_keys') {
          collectedValues.organization_keys = userData?.organizations?.[0]?.organization_key;
          //TODO: When organization field will be multi select support in future then we have to change here also
        } else if (currentAttribute?.name === 'manager_user_key') {
          collectedValues.manager_user_key = userData?.manager?.user_key;
        } else {
          collectedValues[currentAttribute?.name] = userData?.[currentAttribute?.name];
        }

        return collectedValues;
      },
      {}
    );

    return { ...defaultUserValuesCollection };
  };

  const getUserDetails = async (userKey: string) => {
    try {
      setIsLoading(true);
      const results = await AllUsersService.getUserDetailByUserKey({ userKey });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        setUserDetails({});
        setUserAssignedRoleTable({
          rows: [],
          columns: []
        });
        setUserDerivedRoleTable({
          rows: [],
          columns: []
        });
        setIsEditEnabled(false);
      } else if (!results.error.code) {
        // user details
        setUserDetails(results?.data);
        setVersion(results?.data?.version ?? 0);
        const processedUserData = processDefaultUserData(
          results?.data,
          AttributesConfig.USER_DETAILS
        );
        setDefaultUsersValues(processedUserData);
        resetUserDetails(processedUserData);
        const managerOptions = [
          {
            label: results?.data?.manager?.name ?? '-',
            value: results?.data?.manager?.user_key ?? '-',
            details: results?.data?.manager?.email_id ?? '-'
          }
        ];
        setInputOptions((prev) => ({
          ...prev,
          manager_user_key: managerOptions
        }));

        // role tagging
        // store org keys with app keys
        const userOrgKeysWithAppKeys: ILooseObject = {};
        results?.data?.organizations?.forEach((organization: ILooseObject) => {
          if (organization?.organization_key) {
            userOrgKeysWithAppKeys[organization?.organization_key] =
              organization?.applications?.map(
                (application: ILooseObject) => application?.application_key ?? '-'
              );
          }
        });
        setUserOrgWithAppKeys(userOrgKeysWithAppKeys);

        const sortedRows: ILooseObject[] =
          results?.data?.table?.rows?.sort((a: ILooseObject, b: ILooseObject) => {
            const labelA = a?.application_label ?? a?.organization_label ?? '';
            const labelB = b?.application_label ?? b?.organization_label ?? '';

            return labelA > labelB ? 1 : labelA < labelB ? -1 : 0;
          }) ?? [];
        const categorizedTableRows = sortedRows?.reduce(
          (
            collection: { userAssignedRows: ILooseObject[]; userDerivedRows: ILooseObject[] },
            currentRow: ILooseObject
          ) => {
            if (currentRow?.parent_roles && currentRow?.parent_roles?.length > 0) {
              collection.userDerivedRows.push(currentRow);
            } else {
              collection.userAssignedRows.push(currentRow);
            }
            return collection;
          },
          { userAssignedRows: [], userDerivedRows: [] }
        );

        const userAssignedTableData = {
          columns: results?.data?.table?.columns?.filter(
            (column: ILooseObject) => column?.field !== 'parent_roles'
          ),
          rows: categorizedTableRows?.userAssignedRows
        };
        const userDerivedTableData = {
          columns: results?.data?.table?.columns?.filter(
            (column: ILooseObject) => column?.field !== 'organization_label'
          ),
          rows: categorizedTableRows?.userDerivedRows
        };
        setUserAssignedRoleTable(userAssignedTableData);
        setUserAssignedRawTable(userAssignedTableData);

        setUserDerivedRoleTable(userDerivedTableData);
        setUserDerivedRawTable(userDerivedTableData);

        const childrenRoles: string[] = [];
        const selectedRoleKeys: string[] = [];
        sortedRows?.forEach((role: ILooseObject) => {
          if (role?.role_key) {
            selectedRoleKeys.push(role?.role_key);
          }
          if (role?.parent_roles) {
            childrenRoles.push(role?.role_key);
          }
        });
        setInitialRoles(selectedRoleKeys?.sort());
        const commonApps: Set<string> = new Set(); // Ensure Set contains strings
        for (const orgKey in userOrgKeysWithAppKeys) {
          if (requesterOrgWithAppKeys[orgKey]) {
            // both belongs to same org
            const requesterApps = new Set<string>(requesterOrgWithAppKeys[orgKey]);

            for (const appKey of requesterApps) {
              commonApps.add(appKey);
            }
          }
        }
        const commonKeys = [...commonApps]; // Convert Set to array
        setCommonAppKeys(commonKeys);
        setIsEditEnabled(!!validAppKeys?.length && !!commonKeys?.length);
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
      setUserDetails({});
      setIsEditEnabled(false);
      setUserAssignedRoleTable({ columns: [], rows: [] });
    } finally {
      setIsLoading(false);
    }
  };

  const getAllUserTypeList = async (): Promise<IAttributeOptions[]> => {
    try {
      const results = await AllUsersService.getAllUserTypeList();

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        return [];
      } else if (!results.error.code) {
        const convertedUserTypeOption = results?.data?.map((user: ILooseObject) => ({
          label: user?.ui_label ?? '-',
          value: user?.name ?? '-'
        }));
        return convertedUserTypeOption;
      }
      return [];
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
      return [];
    }
  };

  const getAllApplicationsByOrganizations = async (
    organizationKey: string
  ): Promise<IAttributeOptions[]> => {
    try {
      const results = await AllUsersService.getApplicationsListByOrganization({ organizationKey });
      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        return [];
      } else if (!results.error.code) {
        const convertedUserTypeOption = results?.data?.applications?.map(
          (application: ILooseObject) => ({
            label: application?.ui_label ?? '-',
            value: application?.application_key ?? '-'
          })
        );
        return convertedUserTypeOption;
      }
      return [];
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
      return [];
    }
  };

  const getAllRolesByApplications = async (applicationKeys: string[]) => {
    try {
      setIsLoadingRoles(true);
      const results = await AllUsersService.getRolesListByApplications({
        applicationKeys
      });
      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        setUserAssignedRoleTable(userAssignedRawTable);
        setApplicationUiMode('VIEW');
      } else if (!results.error.code) {
        // Separation of roles
        const userAssignableRoles =
          results?.rows?.filter(
            (role: ILooseObject) =>
              role?.application_key && applicationKeys?.includes(role.application_key)
          ) || [];

        const sortedRows = userAssignableRoles?.sort((a: ILooseObject, b: ILooseObject) => {
          const labelA = a?.application_label ?? a?.organization_label ?? '';
          const labelB = b?.application_label ?? b?.organization_label ?? '';

          return labelA > labelB ? 1 : labelA < labelB ? -1 : 0;
        });

        const parentChildKeysRelation = sortedRows?.reduce(
          (relationCollection: ILooseObject, currentRow: ILooseObject) => {
            const isParent = currentRow?.is_parent;
            const isChild = currentRow?.parent_role_key;
            if (isParent) {
              const childCollection =
                currentRow?.child_roles?.map((childRole: ILooseObject) => childRole?.role_key) ??
                [];
              relationCollection[currentRow?.role_key] = {
                isParent: true,
                children: childCollection
              };
            } else if (isChild) {
              relationCollection[currentRow?.role_key] = {
                isParent: false,
                parents: [currentRow?.parent_role_key]
              };
            }
            return relationCollection;
          },
          {}
        );

        setParentChildRelations(parentChildKeysRelation);
        setUserAssignedRoleTable({
          columns: results?.columns,
          rows: sortedRows
        });

        const collectionOfInitialRole = Array.from(
          initialRoles?.reduce((collection: Set<string>, roleKey: string) => {
            const isParent = parentChildKeysRelation?.[roleKey]?.isParent;
            if (isParent) {
              parentChildKeysRelation?.[roleKey]?.children?.forEach((childKey: string) =>
                collection?.delete(childKey)
              );
              collection.add(roleKey);
            } else if (isParent === false) {
              let isParentNotPresent = true;
              parentChildKeysRelation?.[roleKey]?.parents?.forEach((parentKey: string) => {
                if (collection?.has(parentKey)) {
                  isParentNotPresent = false;
                }
              });
              if (isParentNotPresent) {
                collection.add(roleKey);
              }
            } else {
              collection.add(roleKey);
            }
            return collection;
          }, new Set())
        );

        setRowSelectionModel(collectionOfInitialRole?.sort() ?? []);
        resetApplications({
          roles: collectionOfInitialRole ?? []
        });
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsLoadingRoles(false);
    }
  };

  const getAllUsers = async (organizationKey: string) => {
    try {
      setIsOptionsLoading((prev) => ({
        ...prev,
        manager_user_key: true
      }));
      const results = await AllUsersService.getAllUsersByOrganization({
        filters: {
          organization_keys: organizationKey,
          user_type: 'all'
        }
      });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } else if (!results.error.code) {
        const convertedUserOption = results?.data?.map((user: ILooseObject) => ({
          label: user?.name ?? '-',
          value: user?.user_key ?? '-',
          details: user?.email_id
        }));
        setInputOptions((prev) => ({
          ...prev,
          manager_user_key: convertedUserOption
        }));
      }
    } catch (error) {
      enqueueSnackbar(error?.toString(), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsOptionsLoading((prev) => ({
        ...prev,
        manager_user_key: false
      }));
    }
  };

  const handleResetUserDetails = () => {
    resetUserDetails();
    setUserUiMode('VIEW');
  };

  const handleResetApplications = () => {
    resetApplications({ roles: (roleSelectionModel as string[]) ?? [] });
    setApplicationUiMode('VIEW');
    setUserAssignedRoleTable(userAssignedRawTable);
    setUserDerivedRoleTable(useDerivedRawTable);
  };

  const handleDeleteUser = async (userKey: string) => {
    try {
      setIsLoadingDeleteUser(true);
      const results = await AllUsersService.deleteUserByUserKey({
        userKey
      });
      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } else if (!results.error.code) {
        enqueueSnackbar(AppMessages.Success.delete_user, {
          variant: 'success',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        navigate('/users');
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsLoadingDeleteUser(false);
    }
    // TODO: Implement when API is ready
  };

  const handleUpdateUserDetails = (data: ILooseObject) => {
    //TODO: Implement when API is ready
  };

  const handleEditRoles = () => {
    setApplicationUiMode('EDIT');
    if (commonAppKeys?.length > 0) {
      getAllRolesByApplications(commonAppKeys);
    }
  };

  const handleSubmitRoles = async (data: ILooseObject) => {
    const payload = {
      roleKeys: [...(data?.roles ?? [])],
      userKey: userKey ?? '',
      version: version ?? 0
    };

    try {
      const results: IApiClientResponse = await AllRolesService.updateRoles(payload);

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } else if (!results.error.code) {
        enqueueSnackbar(AppMessages.Success.roles_updated, {
          variant: 'success',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.success
        });
        if (userKey) {
          getUserDetails(userKey);
        }
        setApplicationUiMode('VIEW');
        setInitialRoles([]);
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
  };

  useEffect(() => {
    if (getUserPermissionsUserPage()?.includes('user_view') && userKey) {
      getUserDetails(userKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userKey]);

  // useEffect(() => {
  //   if (watchedValues?.organization_keys || defaultUsersValues?.organization_keys) {
  //     getAllUsers(watchedValues?.organization_keys || defaultUsersValues?.organization_keys);
  //   }
  // }, [watchedValues?.organization_keys, defaultUsersValues?.organization_keys]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsOptionsLoading((prev) => ({
          ...prev,
          organization_keys: true,
          user_type: true
        }));

        const organizationList: IAttributeDef[] = store
          .getState()
          .userDetails?.organizations?.map((organization: ILooseObject) => ({
            value: organization?.organization_key ?? '-',
            label: organization?.ui_label ?? '-'
          }));
        const userTypeList = await getAllUserTypeList();

        setInputOptions((prev) => ({
          ...prev,
          organization_keys: organizationList,
          user_type: userTypeList
        }));
      } catch (error) {
        enqueueSnackbar(JSON.stringify(error), {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } finally {
        setIsOptionsLoading((prev) => ({
          ...prev,
          organization_keys: false,
          user_type: false
        }));
      }
      // Start loading for both
    };
    if (getUserPermissionsUserPage()?.includes('user_view')) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const collectionDisabledRows = Array.from(
      roleSelectionModel?.reduce((collection: Set<string>, roleKey: GridRowId) => {
        const isParent = parentChildRelations?.[roleKey]?.isParent;
        if (isParent) {
          // make disable all the children read children
          parentChildRelations?.[roleKey]?.children?.forEach((children: string) => {
            collection.add(children);
          });
        } else if (isParent === false) {
          // make disable to related parent read parents
          parentChildRelations?.[roleKey]?.parents?.forEach((parent: string) => {
            collection.add(parent);
          });
        }
        return collection;
      }, new Set())
    );

    setUnSelectableRows(collectionDisabledRows);
  }, [parentChildRelations, roleSelectionModel]);

  // useEffect(() => {
  //   const fetchApplications = async () => {
  //     if (defaultUsersValues?.organization_keys) {
  //       try {
  //         setIsOptionsLoading((prev) => ({
  //           ...prev,
  //           applications: true
  //         }));
  //         let applicationsValues: IAttributeOptions[] = [];
  //         applicationsValues = await getAllApplicationsByOrganizations(
  //           defaultUsersValues?.organization_keys
  //         );
  //         setInputOptions((prev) => ({ ...prev, applications: applicationsValues }));
  //         // Clean selected values that are no longer valid
  //         const validApplicationKeys = applicationsValues?.map((app) => app.value);
  //         setSelectedValue((prevSelected) =>
  //           prevSelected?.filter((selected) => validApplicationKeys?.includes(selected))
  //         );
  //       } catch (error) {
  //         enqueueSnackbar(JSON.stringify(error), {
  //           variant: 'error',
  //           autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
  //         });
  //       } finally {
  //         setIsOptionsLoading((prev) => ({
  //           ...prev,
  //           applications: false
  //         }));
  //       }
  //     }
  //   };
  //   if (getUserPermissionsUserPage()?.includes('organization_view')) {
  //     fetchApplications();
  //   }
  // }, [defaultUsersValues?.organization_keys]);

  // useEffect(() => {
  //   if (
  //     getUserPermissionsUserPage()?.includes('all_role_view') &&
  //     getApplicationsValues?.applications?.length > 0
  //   ) {
  //     getAllRolesByApplications(getApplicationsValues?.applications);
  //   } else {
  //     setUserAssignedRoleTable({
  //       rows: [],
  //       columns: []
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getApplicationsValues?.applications]);

  if (!isAuthorized) {
    return <NoAuthorization />;
  }

  return (
    <Box>
      <DynamicBreadCrumbs
        breadCrumbsDetails={[
          { key: 1, name: 'All users', redirectPath: '/users' },
          { key: 2, name: 'User Details' }
        ]}
      />
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          component="form"
          onSubmit={handleSubmitUserDetails(handleUpdateUserDetails)}
          display="flex"
          flexDirection="column"
          rowGap={2}
          mt={2}>
          {getUserPermissionsUserPage()?.includes('user_view') ? (
            <Box
              border={1}
              borderColor={lightTheme.palette.grey[300]}
              borderRadius={3}
              sx={{ p: 2, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
              <Typography
                variant="body1"
                fontWeight="bold"
                textTransform="none"
                color="primary.dark"
                textAlign="center">
                User details
              </Typography>
              <Grid2 container columnSpacing={10}>
                {AttributesConfig.USER_DETAILS?.map((attribute: IAttributeDef) => {
                  const inputType = UI_TYPE_INPUT_TYPE_MAP[attribute?.ui_type?.toUpperCase()];
                  const options = attribute?.options ?? inputOptions?.[attribute?.name] ?? [];
                  const inputFieldType: INPUT_TYPES_ENUM =
                    INPUT_FIELD_TYPE_MAP[attribute?.value_type?.toUpperCase()];
                  const isDisabled = attribute?.is_disabled || userUiMode === 'VIEW';
                  const isVisible: boolean = !!attribute?.is_visible;
                  if (!isVisible) {
                    return null;
                  }

                  return (
                    <Grid2
                      container
                      size={{ xs: 6 }}
                      key={attribute?.attribute_id}
                      alignItems="center">
                      {/* UI label */}
                      <Grid2 size={{ xs: 4 }} display="flex" alignItems="center">
                        <Box display="flex" sx={{ textAlign: 'left', pb: 4 }}>
                          <Typography>{`${attribute?.ui_label} ${attribute?.is_mandatory ? '*' : ''}`}</Typography>
                        </Box>
                      </Grid2>
                      {/* DynamicInput */}
                      <Grid2 size={{ xs: 8 }}>
                        {isOptionsLoading?.[attribute?.name] ? (
                          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress size={30} />
                          </Box>
                        ) : (
                          <Controller
                            name={attribute.name as string}
                            control={controlUserDetails}
                            render={({ field: { onChange, onBlur, value, name } }) => (
                              <DynamicInput
                                type={inputType}
                                id={attribute?.attribute_id}
                                label={attribute?.ui_label}
                                value={value}
                                hasError={!!(errorsUserDetails?.[name]?.message as string)}
                                helperText={(errorsUserDetails?.[name]?.message as string) ?? ' '}
                                options={options}
                                maxLength={attribute?.max_length}
                                disabled={isDisabled}
                                placeholder={attribute?.placeholder}
                                disableClearable={attribute?.disable_clearable}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                  onChange(e);
                                  clearErrorsUserDetails(name);
                                }}
                                onBlur={onBlur}
                                inputType={inputFieldType}
                              />
                            )}
                          />
                        )}
                      </Grid2>
                    </Grid2>
                  );
                })}
              </Grid2>
              <Box display="flex" columnGap={1} justifyContent="flex-end">
                {userUiMode === 'VIEW' ? (
                  <>
                    {getUserPermissionsUserPage()?.includes('user_delete') && (
                      <LoadingButton
                        size="small"
                        variant="outlined"
                        loading={isSubmittingUserDetails}
                        onClick={() => setIsOpenDeleteDialog(true)}
                        color="error"
                        disabled={userDetails?.user_key === meApiData?.user_key}
                        sx={{ textTransform: 'none' }}>
                        Delete user
                      </LoadingButton>
                    )}
                    {/* TODO: update the permission while integrating the edit flow */}
                    {getUserPermissionsUserPage()?.includes('all_role_view') && (
                      <LoadingButton
                        size="small"
                        variant="outlined"
                        onClick={() => setUserUiMode('EDIT')}
                        loading={isSubmittingUserDetails}
                        disabled //TODO: Remove it when update API is ready
                        sx={{ textTransform: 'none' }}>
                        Edit user
                      </LoadingButton>
                    )}
                  </>
                ) : (
                  <>
                    {/* TODO: update the permission while integrating the edit flow */}
                    {getUserPermissionsUserPage()?.includes('all_role_view') && (
                      <>
                        <LoadingButton
                          size="small"
                          variant="outlined"
                          sx={{ textTransform: 'none' }}
                          onClick={handleResetUserDetails}>
                          Cancel
                        </LoadingButton>
                        <LoadingButton
                          size="small"
                          variant="contained"
                          type="submit"
                          loading={isSubmittingUserDetails}
                          disabled={
                            Object.keys(errorsUserDetails).length > 0 || !isDirtyUserDetails
                          }
                          sx={{ textTransform: 'none' }}>
                          Save
                        </LoadingButton>
                      </>
                    )}
                  </>
                )}
              </Box>
            </Box>
          ) : (
            <NoAuthorization />
          )}
          <Box
            border={1}
            borderColor={lightTheme.palette.grey[300]}
            borderRadius={3}
            sx={{ p: 2, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
            <Box display="flex" alignItems="center" width="100%" position="relative" minHeight={50}>
              <Typography
                variant="body1"
                fontWeight="bold"
                textTransform="none"
                color="primary.dark"
                sx={{
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  whiteSpace: 'nowrap'
                }}>
                User assigned roles
              </Typography>

              {/* Spacer to push buttons to the right */}
              <Box sx={{ flexGrow: 1 }} />

              {/* Edit roles section */}
              {isEditEnabled && (
                <Box display="flex" columnGap={1} justifyContent="flex-end">
                  {applicationUiMode === 'VIEW' ? (
                    <LoadingButton
                      size="small"
                      variant="outlined"
                      onClick={handleEditRoles}
                      sx={{ textTransform: 'none' }}>
                      Edit roles
                    </LoadingButton>
                  ) : (
                    <>
                      <LoadingButton
                        size="small"
                        variant="outlined"
                        sx={{ textTransform: 'none' }}
                        onClick={handleResetApplications}>
                        Cancel
                      </LoadingButton>
                      <LoadingButton
                        size="small"
                        variant="contained"
                        type="submit"
                        onClick={handleSubmitApplications(handleSubmitRoles)}
                        loading={isSubmittingApplications}
                        disabled={!isDirtyApplications}
                        sx={{ textTransform: 'none' }}>
                        Save
                      </LoadingButton>
                    </>
                  )}
                </Box>
              )}
            </Box>
            {/* role tagging section */}
            <Box
              component="form"
              sx={{ mt: 2 }}
              onSubmit={handleSubmitApplications(handleSubmitRoles)}>
              <Controller
                name="roles"
                control={controlApplications}
                render={({ field: { onChange, value } }) => {
                  return (
                    <DataGrid
                      getRowId={(row) => row.role_key}
                      loading={isLoadingRoles}
                      rows={userAssignedRoleTable?.rows}
                      columns={userAssignedRoleTable.columns}
                      disableRowSelectionOnClick
                      disableColumnSelector
                      disableColumnMenu
                      disableDensitySelector
                      getRowHeight={() => 'auto'}
                      {...(applicationUiMode === 'EDIT' && {
                        checkboxSelection: true,
                        rowSelectionModel: value,
                        onRowSelectionModelChange: (newRowSelectionModel) => {
                          setRowSelectionModel(newRowSelectionModel?.toSorted());
                          onChange(newRowSelectionModel?.toSorted());
                        },
                        isRowSelectable: (params: GridRowParams) => {
                          return !unSelectableRows?.includes(params?.row?.role_key);
                        }
                      })}
                      sx={{
                        '& .MuiDataGrid-columnHeaderTitle': {
                          textOverflow: 'clip',
                          whiteSpace: 'break-spaces',
                          lineHeight: 1.15,
                          fontWeight: 600
                        },
                        '& .MuiDataGrid-columnHeaderCheckbox': {
                          pointerEvents: 'none'
                        },
                        '& .MuiDataGrid-row': {
                          minHeight: '52px !important'
                        },
                        '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        '& .MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                          outline: 'none'
                        },
                        '& .MuiDataGrid-main': {
                          overflow: 'unset'
                        },
                        '& .MuiDataGrid-columnHeaders': {
                          position: 'sticky',
                          top: 63,
                          zIndex: 1
                        },
                        '& .MuiDataGrid-container--top': {
                          zIndex: 1
                        },
                        '& .MuiDataGrid-columnHeader': {
                          backgroundColor: lightTheme.palette.grey[100]
                        }
                      }}
                    />
                  );
                }}
              />
            </Box>

            <Box display="flex" alignItems="center" width="100%" position="relative" minHeight={50}>
              <Typography
                variant="body1"
                fontWeight="bold"
                textTransform="none"
                color="primary.dark"
                sx={{
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  whiteSpace: 'nowrap'
                }}>
                User derived roles
              </Typography>

              {/* Spacer to push buttons to the right */}
              <Box sx={{ flexGrow: 1 }} />
            </Box>
            {/* role tagging section */}
            <Box component="form" sx={{ mt: 2 }}>
              <DataGrid
                getRowId={(row) => row.role_key}
                rows={userDerivedRoleTable?.rows}
                columns={userDerivedRoleTable.columns}
                disableRowSelectionOnClick
                disableColumnSelector
                disableColumnMenu
                disableDensitySelector
                getRowHeight={() => 'auto'}
                sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                    textOverflow: 'clip',
                    whiteSpace: 'break-spaces',
                    lineHeight: 1.15,
                    fontWeight: 600
                  },
                  '& .MuiDataGrid-row': {
                    minHeight: '52px !important'
                  },
                  '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
                    outline: 'none'
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none'
                  },
                  '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none'
                  },
                  '& .MuiDataGrid-main': {
                    overflow: 'unset'
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    position: 'sticky',
                    top: 63,
                    zIndex: 1
                  },
                  '& .MuiDataGrid-container--top': {
                    zIndex: 1
                  },
                  '& .MuiDataGrid-columnHeader': {
                    backgroundColor: lightTheme.palette.grey[100]
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
      <ConfirmDialog
        content={`Are you sure you want to delete ${userDetails?.name} ?`}
        handleCloseAction={() => setIsOpenDeleteDialog(false)}
        handleSaveAction={() => handleDeleteUser(userDetails?.user_key)}
        open={isOpenDeleteDialog}
        isLoadingSave={isLoadingDeleteUser}
        saveActionName="Confirm"
      />
    </Box>
  );
};

export default UserDetails;
