import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { DataGrid } from '@mui/x-data-grid';
import { Box, CircularProgress, Divider, Grid2, Typography } from '@mui/material';

import { RootState } from '@/src/datastore/store';
import {
  IAttributeOptions,
  ILooseObject,
  INPUT_FIELD_TYPE_MAP,
  INPUT_TYPES_ENUM,
  ListingTableDataStateType,
  UI_TYPE_INPUT_TYPE_MAP
} from '@/src/types/common.interface';
import AppConfig from '@/src/config/AppConfig';
import AttributesConfig, { IAttributeDef } from '@/src/config/AttributesConfig';
import AllRolesService from '@/src/services/all-roles/allRolesService';
import CustomTablePaginationActions from '@/src/components/common/CustomTablePaginationActions';
import DynamicBreadCrumbs from '@/src/components/common/DynamicBreadCrumbs';
import NoAuthorization from '@/src/components/common/NoAuthorization';
import DynamicInput from '@/src/components/common/DynamicInput';
import lightTheme from '@/src/styles/themes/lightTheme';

const RoleDetails = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOptionsLoading, setIsOptionsLoading] = useState<ILooseObject>({});
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const [roleDetails, setRoleDetails] = useState<ILooseObject>({});
  const [defaultRoleValues, setDefaultRolesValues] = useState<ILooseObject>({});
  const [inputOptions, setInputOptions] = useState<ILooseObject>({});
  const [roleUiMode] = useState<'VIEW' | 'EDIT'>('VIEW');
  const [userRoleTableDetails, setUserRoleTableDetails] = useState<ListingTableDataStateType>({
    rows: [],
    columns: []
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const roleKey = searchParams.get('roleKey');
  const applicationKey = searchParams.get('applicationKey');

  const userAppPermissions: ILooseObject = useSelector(
    (state: RootState) => state?.userDetails?.OptionsAndPermissions?.appPermissions
  );

  const getAppPermissions = () => {
    //filtering permissions who have users applications
    return Object.entries(userAppPermissions as Record<string, string[]>)?.reduce(
      (acc: string[], [key, permissions]: [string, string[]]) => {
        if (key === applicationKey) {
          return Array.from(new Set([...acc, ...permissions]));
        }
        return acc;
      },
      []
    );
  };

  const processDefaultRoleData = (data: ILooseObject) => {
    const application = data?.application?.ui_label ?? '-';
    const permissions =
      data?.permissions?.map((permission: ILooseObject) => permission?.permission_key) ?? [];
    const role = data?.ui_label ?? '-';

    return { application, permissions, role };
  };

  const {
    control: controlRoleDetails,
    handleSubmit: handleSubmitRoleDetails,
    formState: {
      errors: errorsRoleDetails
      // isSubmitting: isSubmittingRoleDetails,
      // isDirty: isDirtyRoleDetails //TODO: when update flow will be implemented enable that time
    },
    clearErrors: clearErrorsRoleDetails,
    // getValues: getValuesRoleDetails, //TODO: when update flow will be implemented enable that time
    reset: resetRoleDetails
  } = useForm({
    defaultValues: defaultRoleValues,
    // resolver: yupResolver(schema, { abortEarly: false }),
    mode: 'onBlur'
  });

  const getRoleDetails = async (roleKey: string) => {
    try {
      setIsLoading(true);
      const results = await AllRolesService.getRoleDetails({ roleKey });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        setRoleDetails({});
      } else if (!results.error.code) {
        setRoleDetails(results?.data);
        const processedRoleData = processDefaultRoleData(results?.data);
        setDefaultRolesValues(processedRoleData);
        const permissionsOptions: IAttributeOptions[] = results?.data?.permissions?.map(
          (app: ILooseObject) => ({
            label: app?.ui_label ?? '-',
            value: app?.permission_key ?? '-'
          })
        );
        setInputOptions((prev: ILooseObject) => ({
          ...prev,
          permissions: permissionsOptions
        }));
        resetRoleDetails(processedRoleData);
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
      setRoleDetails({});
    } finally {
      setIsLoading(false);
    }
  };

  // TODO: call this function while integrating edit flow
  const getAllPermissions = async (applicationKey: string) => {
    try {
      setIsOptionsLoading((prev: ILooseObject) => ({ ...prev, permissions: true }));
      const results = await AllRolesService.getAllPermissions({ applicationKey });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        setRoleDetails({});
      } else if (!results.error.code) {
        setInputOptions((prev: ILooseObject) => ({
          ...prev,
          permissions: results?.data?.map((app: ILooseObject) => ({
            label: app?.ui_label ?? '-',
            value: app?.permission_key ?? '-'
          }))
        }));
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsOptionsLoading((prev: ILooseObject) => ({ ...prev, permissions: false }));
    }
  };

  const getAllUsersByRoleKey = async (roleKey: string) => {
    try {
      setIsLoadingUsers(true);
      const results = await AllRolesService.getAllUsersByRole({ roleKey });
      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
        setRoleDetails({});
      } else if (!results.error.code) {
        setUserRoleTableDetails({ rows: results?.rows ?? [], columns: results?.columns ?? [] });
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const handleUpdateRoleDetails = () => {
    //TODO: need to implement the update role details
  };

  useEffect(() => {
    if (roleKey) {
      if (getAppPermissions()?.includes('role_view')) {
        getRoleDetails(roleKey);
      }
      if (getAppPermissions()?.includes('role_users_view')) {
        getAllUsersByRoleKey(roleKey);
      }
    } else {
      enqueueSnackbar('Role key is missing', {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleKey]);

  if (
    !getAppPermissions()?.includes('role_view') ||
    !getAppPermissions()?.includes('role_users_view')
  ) {
    return <NoAuthorization />;
  }

  return (
    <Box>
      <DynamicBreadCrumbs
        breadCrumbsDetails={[
          { key: 1, name: 'All roles', redirectPath: '/roles' },
          { key: 2, name: 'Role Details' }
        ]}
      />
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          component="form"
          onSubmit={handleSubmitRoleDetails(handleUpdateRoleDetails)}
          display="flex"
          flexDirection="column"
          rowGap={2}
          mt={2}>
          <Box
            border={1}
            borderColor={lightTheme.palette.grey[300]}
            borderRadius={3}
            sx={{ p: 2, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
            <Typography
              variant="body1"
              fontWeight="bold"
              textTransform="none"
              color="primary.dark"
              pb={2}
              textAlign="center">
              Role details
            </Typography>
            {getAppPermissions()?.includes('role_view') ? (
              <Grid2 container columnSpacing={10}>
                {AttributesConfig.ROLE_DETAILS.map((attribute: IAttributeDef) => {
                  const inputType = UI_TYPE_INPUT_TYPE_MAP[attribute?.ui_type?.toUpperCase()];
                  const options = attribute?.options ?? inputOptions?.[attribute?.name] ?? [];
                  const inputFieldType: INPUT_TYPES_ENUM =
                    INPUT_FIELD_TYPE_MAP[attribute?.value_type?.toUpperCase()];
                  const isDisabled = attribute?.is_disabled || roleUiMode === 'VIEW';
                  const isVisible: boolean = !!attribute?.is_visible;
                  if (!isVisible) {
                    return null;
                  }

                  return (
                    <Grid2
                      container
                      size={{ xs: attribute?.name === 'permissions' ? 12 : 6 }}
                      key={attribute?.attribute_id}
                      alignItems="center"
                      display="block">
                      {/* UI label */}
                      <Box display="flex" pb={1}>
                        <Typography>{`${attribute?.ui_label} ${attribute?.is_mandatory ? '*' : ''}`}</Typography>
                      </Box>
                      {/* DynamicInput */}
                      {isOptionsLoading?.[attribute?.name] ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <CircularProgress size={30} />
                        </Box>
                      ) : (
                        <Controller
                          name={attribute.name as string}
                          control={controlRoleDetails}
                          render={({ field: { onChange, onBlur, value, name } }) => (
                            <DynamicInput
                              type={inputType}
                              id={attribute?.attribute_id}
                              label={attribute?.ui_label}
                              value={value}
                              required={attribute?.is_mandatory}
                              hasError={!!(errorsRoleDetails?.[name]?.message as string)}
                              helperText={(errorsRoleDetails?.[name]?.message as string) ?? ' '}
                              options={options}
                              maxLength={attribute?.max_length}
                              disabled={isDisabled}
                              placeholder={attribute?.placeholder}
                              disableClearable={attribute?.disable_clearable}
                              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                onChange(e);
                                clearErrorsRoleDetails(name);
                              }}
                              onBlur={onBlur}
                              inputType={inputFieldType}
                            />
                          )}
                        />
                      )}
                    </Grid2>
                  );
                })}
              </Grid2>
            ) : (
              <NoAuthorization />
            )}
          </Box>
          <Divider />
          <Box
            border={1}
            borderColor={lightTheme.palette.grey[300]}
            borderRadius={3}
            sx={{ p: 2, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
            <Typography
              variant="body1"
              fontWeight="bold"
              textTransform="none"
              color="primary.dark"
              pb={2}
              textAlign="center">
              Users with above roles
            </Typography>
            {getAppPermissions()?.includes('role_users_view') ? (
              <DataGrid
                getRowId={(row) => row?.user_key}
                rows={userRoleTableDetails.rows}
                columns={userRoleTableDetails.columns}
                disableRowSelectionOnClick
                disableColumnSelector
                disableColumnMenu
                disableDensitySelector
                getRowHeight={() => 'auto'}
                sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                    textOverflow: 'clip',
                    whiteSpace: 'break-spaces',
                    lineHeight: 1.15,
                    fontWeight: 600
                  },
                  '& .MuiDataGrid-row': {
                    minHeight: '52px !important'
                  },
                  '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
                    outline: 'none'
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none'
                  },
                  '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none'
                  },
                  '& .MuiDataGrid-main': {
                    overflow: 'unset'
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    position: 'sticky',
                    top: 63,
                    zIndex: 1
                  },
                  '& .MuiDataGrid-container--top': {
                    zIndex: 1
                  },
                  '& .MuiDataGrid-columnHeader': {
                    backgroundColor: lightTheme.palette.grey[100]
                  }
                }}
                slotProps={{
                  pagination: {
                    ActionsComponent: CustomTablePaginationActions
                  },
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 }
                  }
                }}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 20, page: 0 }
                  }
                }}
                paginationMode="client"
                loading={isLoadingUsers}
                pageSizeOptions={[20, 50, 100]}
              />
            ) : (
              <NoAuthorization />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RoleDetails;
