import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar } from 'notistack';
import { Box, Button, Grid2, Typography } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

import AppConfig from '@/src/config/AppConfig';
import AttributesConfig, { IAttributeDef } from '@/src/config/AttributesConfig';
import DynamicBreadCrumbs from '@/src/components/common/DynamicBreadCrumbs';
import DynamicInput from '@/src/components/common/DynamicInput';
import SaveAndCancel from '@/src/components/common/SaveAndCancel';
import AddPermissionDialog from '@/src/components/api-config/AddPermissionDialog';
import NoAuthorization from '@/src/components/common/NoAuthorization';
import { RootState } from '@/src/datastore/store';
import UseNavigation from '@/src/hooks/useNavigation';
import AllRolesService from '@/src/services/all-roles/allRolesService';
import AllApiConfigService from '@/src/services/api-config/allApiConfigService';
import SchemaValidations from '@/src/utils/SchemaValidations';
import {
  ILooseObject,
  INPUT_FIELD_TYPE_MAP,
  INPUT_TYPES_ENUM,
  UI_TYPE_INPUT_TYPE_MAP
} from '@/src/types/common.interface';
import lightTheme from '@/src/styles/themes/lightTheme';
import AppMessages from '@/src/config/message_config/AppMessages';

const AddApiConfig = () => {
  const [inputOptions, setInputOptions] = useState<ILooseObject>({}); // contains all the options
  const [isOptionsLoading, setIsOptionsLoading] = useState<ILooseObject>({}); // contains options loading status
  const [isOpenPermissionDialog, setIsOpenPermissionDialog] = useState<boolean>(false);

  const location = useLocation();
  const navigation = UseNavigation();

  const searchParams = new URLSearchParams(location.search);
  const applicationKey = searchParams.get('applicationKey');

  const schema = SchemaValidations.AddApiConfig(AttributesConfig.ADD_API_CONFIG_INPUT);

  const {
    control: addConfigControl,
    handleSubmit: handleAddConfigSubmit,
    formState: {
      errors: addConfigErrors,
      isSubmitting: isAddConfigSubmitting,
      isDirty: isAddConfigDirty
    },
    clearErrors: clearAddConfigErrors,
    reset: resetAddConfig
  } = useForm({
    defaultValues: AttributesConfig.ADD_API_CONFIG_INPUT?.reduce(
      (acc: ILooseObject, attribute: IAttributeDef) => {
        if (attribute?.name === 'version') {
          acc[attribute?.name] = 0;
        } else {
          acc[attribute?.name] = '';
        }
        return acc;
      },
      {}
    ),
    resolver: yupResolver(schema, { abortEarly: false }),
    mode: 'onBlur'
  });

  const userAppPermissions: ILooseObject = useSelector(
    (state: RootState) => state?.userDetails?.OptionsAndPermissions?.appPermissions
  );

  const getAppPermissions = () => {
    //filtering permissions who have users applications
    return Object.entries(userAppPermissions as Record<string, string[]>)?.reduce(
      (acc: string[], [key, permissions]: [string, string[]]) => {
        if (key === applicationKey) {
          return Array.from(new Set([...acc, ...permissions]));
        }
        return acc;
      },
      []
    );
  };

  const getAllPermissions = async (applicationKey: string) => {
    try {
      setIsOptionsLoading((prev: ILooseObject) => ({ ...prev, permissionKeys: true }));
      const results = await AllRolesService.getAllPermissions({ applicationKey });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } else if (!results.error.code) {
        const permissionKeys =
          results?.data?.map((app: ILooseObject) => ({
            label: app?.ui_label ?? '-',
            value: app?.permission_key ?? '-'
          })) ?? [];
        setInputOptions((prev: ILooseObject) => ({
          ...prev,
          permission_keys: permissionKeys
        }));
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsOptionsLoading((prev: ILooseObject) => ({ ...prev, permissionKeys: false }));
    }
  };

  const createNewApiConfig = async (data: ILooseObject) => {
    try {
      const results = await AllApiConfigService.createNewApiConfig({
        applicationKey: applicationKey ?? '',
        payload: data
      });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } else if (!results.error.code) {
        enqueueSnackbar(AppMessages.Success.ApiConfigAdded, {
          variant: 'success',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.success
        });
        navigation.navigateTo('/api-config');
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    } finally {
      setIsOptionsLoading((prev: ILooseObject) => ({ ...prev, permissionKeys: false }));
    }
  };

  const handleClosePermissionDialog = () => {
    setIsOpenPermissionDialog(false);
  };

  useEffect(() => {
    if (applicationKey) {
      getAllPermissions(applicationKey);
    }
  }, [applicationKey]);

  if (!getAppPermissions()?.includes('api_config_create')) {
    return <NoAuthorization />;
  }

  return (
    <Box>
      <DynamicBreadCrumbs
        breadCrumbsDetails={[
          { key: 1, name: 'All API config', redirectPath: '/api-config' },
          { key: 2, name: 'Add API config' }
        ]}
      />
      <Typography variant="h5" sx={{ my: 2 }} color="primary.dark">
        Add api config
      </Typography>
      <Box component="form" onSubmit={handleAddConfigSubmit(createNewApiConfig)}>
        <Box
          border={1}
          borderColor={lightTheme.palette.grey[300]}
          borderRadius={3}
          sx={{ p: 2, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
          <Grid2 container columnSpacing={10}>
            {AttributesConfig.ADD_API_CONFIG_INPUT?.map((attribute: IAttributeDef) => {
              const inputType = UI_TYPE_INPUT_TYPE_MAP[attribute?.ui_type?.toUpperCase()];
              const options = attribute?.options ?? inputOptions[attribute?.name] ?? [];
              const inputFieldType: INPUT_TYPES_ENUM =
                INPUT_FIELD_TYPE_MAP[
                  typeof attribute?.value_type === 'string'
                    ? attribute?.value_type?.toUpperCase()
                    : ''
                ];
              const isVisible: boolean = !!attribute?.is_visible;
              if (!isVisible) {
                return null;
              }
              const isRequired = attribute?.is_mandatory;

              return (
                <Grid2 container size={{ xs: 6 }} key={attribute?.attribute_id} alignItems="center">
                  {/* UI label */}
                  <Grid2 size={{ xs: 4 }} display="flex" alignItems="center">
                    <Box sx={{ textAlign: 'left', pb: 4 }}>
                      <Typography>{`${attribute?.ui_label} ${isRequired ? '*' : ''}`}</Typography>
                    </Box>
                  </Grid2>
                  {/* DynamicInput */}
                  <Grid2 size={{ xs: 8 }}>
                    <Controller
                      name={attribute.name as string}
                      control={addConfigControl}
                      render={({ field: { onChange, onBlur, value, name } }) => (
                        <DynamicInput
                          type={inputType}
                          id={attribute?.attribute_id}
                          label={attribute?.ui_label}
                          value={value}
                          hasError={!!(addConfigErrors?.[name]?.message as string)}
                          helperText={(addConfigErrors?.[name]?.message as string) ?? ' '}
                          options={options}
                          loading={isOptionsLoading?.[attribute?.name]}
                          maxLength={attribute?.max_length}
                          placeholder={attribute?.placeholder}
                          disableClearable={attribute?.disable_clearable}
                          onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            onChange(e);
                            clearAddConfigErrors(name);
                          }}
                          onBlur={onBlur}
                          inputType={inputFieldType}
                        />
                      )}
                    />
                  </Grid2>
                </Grid2>
              );
            })}
          </Grid2>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              startIcon={<PersonAddAltIcon />}
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={() => {
                setIsOpenPermissionDialog(true);
              }}>
              Add new permission
            </Button>
          </Box>
        </Box>
        {getAppPermissions()?.includes('api_config_create') && (
          <SaveAndCancel
            handleCancel={() => {
              resetAddConfig();
            }}
            isLoading={isAddConfigSubmitting}
            disableSave={
              isAddConfigSubmitting || Object.keys(addConfigErrors).length > 0 || !isAddConfigDirty
            }
            disableReset={!isAddConfigDirty && Object.keys(addConfigErrors).length === 0}
          />
        )}
      </Box>
      <AddPermissionDialog
        handleCloseAction={handleClosePermissionDialog}
        isOpenPermissionDialog={isOpenPermissionDialog}
        applicationKey={applicationKey ?? ''}
      />
    </Box>
  );
};

export default AddApiConfig;
