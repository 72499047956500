/* eslint-disable no-unused-vars */
import { FocusEventHandler } from 'react';
import { SxProps, TextFieldVariants } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

export interface ILooseObject {
  [key: string]: any;
}

export interface IPagination {
  page: number;
  size: number;
}

export interface ISorting {
  field: string;
  order: 'asc' | 'desc';
}

export interface IGetApiRequestParams {
  pagination?: IPagination;
  sorting?: ISorting[];
  filters?: ILooseObject;
}

export interface IApiClientResponse {
  headers?: ILooseObject;
  data: ILooseObject;
  error: {
    code: number;
    message: string;
  };
  errors?: ILooseObject;
}

export interface IApiClientInput {
  url: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: ILooseObject;
  body?: ILooseObject;
  // signal?: GenericAbortSignal; //TODO: Implement abort signal
}

export enum DYNAMIC_INPUT_TYPES_ENUM {
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  INTEGER = 'INTEGER',
  DYNAMIC_MULTI_CHIPS_SELECT = 'DYNAMIC_MULTI_CHIPS_SELECT',
  BOOLEAN = 'BOOLEAN',
  MULTI_SELECT = 'MULTI_SELECT'
}

export enum INPUT_TYPES_ENUM {
  TEXT = 'text',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  ARRAY = 'array'
}

export interface IAttributeOptions {
  value: string | boolean;
  label: string;
  details?: string;
}

type UI_TYPE_INPUT_TYPE_MAP_TYPE = {
  [key: string]: DYNAMIC_INPUT_TYPES_ENUM;
};

type INPUT_FIELD_TYPE_MAP_TYPE = {
  [key: string]: INPUT_TYPES_ENUM;
};

export const UI_TYPE_INPUT_TYPE_MAP: UI_TYPE_INPUT_TYPE_MAP_TYPE = {
  STRING: DYNAMIC_INPUT_TYPES_ENUM?.TEXT,
  SELECT: DYNAMIC_INPUT_TYPES_ENUM?.SELECT,
  INTEGER: DYNAMIC_INPUT_TYPES_ENUM?.INTEGER,
  MULTI_SELECT_CHIPS: DYNAMIC_INPUT_TYPES_ENUM?.DYNAMIC_MULTI_CHIPS_SELECT,
  MULTI_SELECT: DYNAMIC_INPUT_TYPES_ENUM?.MULTI_SELECT,
  BOOLEAN: DYNAMIC_INPUT_TYPES_ENUM?.BOOLEAN
};

export const INPUT_FIELD_TYPE_MAP: INPUT_FIELD_TYPE_MAP_TYPE = {
  TEXT: INPUT_TYPES_ENUM?.TEXT,
  NUMBER: INPUT_TYPES_ENUM?.NUMBER,
  BOOLEAN: INPUT_TYPES_ENUM?.BOOLEAN,
  ARRAY: INPUT_TYPES_ENUM?.ARRAY
};

export interface IDynamicInputProp {
  type: DYNAMIC_INPUT_TYPES_ENUM;
  id: number;
  value: string | string[];
  inputType: INPUT_TYPES_ENUM;
  required?: boolean;
  hasError: boolean;
  name?: string;
  label?: string;
  placeholder?: string;
  options?: IAttributeOptions[];
  helperText?: string;
  fullWidth?: boolean;
  variant?: TextFieldVariants; //'outlined' | 'standard' | 'filled'
  disabled?: boolean;
  sx?: SxProps;
  size?: 'small';
  onChange?: Function;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  maxLength?: number;
  disableClearable?: boolean;
  loading?: boolean;
}

export interface IBreadCrumbsDef {
  key: string | number;
  name: string;
  redirectPath?: string;
}

export interface ListingTableDataStateType {
  columns: GridColDef[];
  rows: ILooseObject[];
}
