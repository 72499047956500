import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar } from 'notistack';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

import AttributesConfig, { IAttributeDef } from '@/src/config/AttributesConfig';
import AppConfig from '@/src/config/AppConfig';
import AppMessages from '@/src/config/message_config/AppMessages';
import {
  ILooseObject,
  INPUT_FIELD_TYPE_MAP,
  INPUT_TYPES_ENUM,
  UI_TYPE_INPUT_TYPE_MAP
} from '@/src/types/common.interface';
import SchemaValidations from '@/src/utils/SchemaValidations';
import AllApiConfigService from '@/src/services/api-config/allApiConfigService';
import DynamicInput from '@/src/components/common/DynamicInput';

type AddPermissionDialogProps = {
  isOpenPermissionDialog: boolean;
  handleCloseAction: () => void;
  applicationKey: string;
};

const AddPermissionDialog = (props: AddPermissionDialogProps) => {
  const { isOpenPermissionDialog, handleCloseAction, applicationKey } = props;

  const {
    control: createPermissionControl,
    handleSubmit: handleCreatePermissionSubmit,
    formState: {
      errors: createPermissionErrors,
      isSubmitting: isCreatePermissionSubmitting,
      isDirty: isCreatePermissionDirty
    },
    clearErrors: clearCreatePermissionErrors,
    reset: resetCreatePermission
  } = useForm({
    defaultValues: AttributesConfig.CREATE_PERMISSION_INPUT?.reduce(
      (acc: ILooseObject, attribute: IAttributeDef) => {
        acc[attribute?.name] = '';
        return acc;
      },
      {}
    ),
    resolver: yupResolver(
      SchemaValidations.CreatePermission(AttributesConfig.CREATE_PERMISSION_INPUT),
      { abortEarly: false }
    ),
    mode: 'onBlur'
  });

  const createNewPermission = async (data: ILooseObject) => {
    try {
      const results = await AllApiConfigService.createNewPermission({
        payload: data,
        applicationKey
      });

      if (results.error.message) {
        enqueueSnackbar(results.error.message, {
          variant: 'error',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
        });
      } else if (!results.error.code) {
        enqueueSnackbar(AppMessages.Success.permission_added, {
          variant: 'success',
          autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.success
        });
        resetAndClose();
        window.location.reload();
      }
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: 'error',
        autoHideDuration: AppConfig.SNACKBAR_AUTO_HIDE_DURATIONS.error
      });
    }
  };

  const resetAndClose = () => {
    handleCloseAction();
    resetCreatePermission();
  };

  return (
    <Dialog open={isOpenPermissionDialog} maxWidth="sm" fullWidth onClose={resetAndClose}>
      <Box component="form" onSubmit={handleCreatePermissionSubmit(createNewPermission)}>
        <DialogTitle id="alert-dialog-title">Create a new permission</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={resetAndClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <DialogContent>
          <Box display="flex" flexDirection="column">
            {AttributesConfig.CREATE_PERMISSION_INPUT?.map((attribute: IAttributeDef) => {
              const inputType = UI_TYPE_INPUT_TYPE_MAP[attribute?.ui_type?.toUpperCase()];
              const inputFieldType: INPUT_TYPES_ENUM =
                INPUT_FIELD_TYPE_MAP[
                  typeof attribute?.value_type === 'string'
                    ? attribute?.value_type?.toUpperCase()
                    : ''
                ];
              const isVisible: boolean = !!attribute?.is_visible;
              if (!isVisible) {
                return null;
              }

              return (
                <Box key={attribute?.attribute_id}>
                  <Typography>{`${attribute?.ui_label} ${attribute?.is_mandatory ? '*' : ''}`}</Typography>
                  <Controller
                    name={attribute.name as string}
                    control={createPermissionControl}
                    render={({ field: { onChange, onBlur, value, name } }) => (
                      <DynamicInput
                        type={inputType}
                        id={attribute?.attribute_id}
                        label={attribute?.ui_label}
                        value={value}
                        hasError={!!(createPermissionErrors?.[name]?.message as string)}
                        helperText={(createPermissionErrors?.[name]?.message as string) ?? ' '}
                        maxLength={attribute?.max_length}
                        placeholder={attribute?.placeholder}
                        disableClearable={attribute?.disable_clearable}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          onChange(e);
                          clearCreatePermissionErrors(name);
                        }}
                        onBlur={onBlur}
                        inputType={inputFieldType}
                      />
                    )}
                  />
                </Box>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="outlined" color="error" onClick={resetAndClose}>
            Cancel
          </Button>
          <LoadingButton
            size="small"
            variant="contained"
            color="primary"
            type="submit"
            loading={isCreatePermissionSubmitting}
            disabled={
              isCreatePermissionSubmitting ||
              !isCreatePermissionDirty ||
              Object.keys(createPermissionErrors)?.length > 0
            }
            autoFocus>
            Create
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddPermissionDialog;
