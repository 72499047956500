import { VariantType } from "notistack";

type ISnackbar_Auto_Hide_Durations = {
  [variant in VariantType]: number;
};

export interface INavigationMenu {
  id: number;
  label: string;
  route: string;
  applicableRoutes: string[];
  icon?: string;
  ActiveIcon?: string;
  InActiveIcon?: string;
  requiredAction: string;
}

type AppConfigType = {
  APP_DISPLAY_NAME: string;
  SNACKBAR_AUTO_HIDE_DURATIONS: ISnackbar_Auto_Hide_Durations;
  NAVIGATION_MENUS: INavigationMenu[];
};

const AppConfig: AppConfigType = {
  APP_DISPLAY_NAME: "Central Access Management",
  SNACKBAR_AUTO_HIDE_DURATIONS: {
    success: 3000,
    error: 5000,
    warning: 4000,
    info: 3000,
    default: 3000,
  },
  NAVIGATION_MENUS: [
    {
      id: 1,
      label: "All users",
      route: "/",
      applicableRoutes: ["/"],
      icon: "users",
      requiredAction: "",
    },
    {
      id: 2,
      label: "All roles",
      route: "/all-roles",
      applicableRoutes: ["/all-roles"],
      ActiveIcon: "activeRoles",
      InActiveIcon: "inActiveRoles",
      requiredAction: "",
    },
  ],
};

export default AppConfig;
