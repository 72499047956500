import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ILooseObject } from '@/src/types/common.interface';

const initialState: ILooseObject = {};
const apiConfig = createSlice({
  name: 'apiConfig',
  initialState,
  reducers: {
    saveApiConfig: (state, action: PayloadAction<ILooseObject>) => {
      return { ...state, ...action.payload };
    },
    clearApiConfig: () => {
      return {}; // Reset state to an empty object
    }
  }
});

export const { saveApiConfig, clearApiConfig } = apiConfig.actions;
export default apiConfig.reducer;
